var app = require('ampersand-app');
var Router = require('ampersand-router');
var HomePage = require('./pages/home');
var VisionPage = require('./pages/vision');
var NotFoundPage = require('./pages/not-found');
var WorkshopPage = require('./pages/workshop');
var workshopPages = {
	services: require('./pages/workshop/services'),
	clients: require('./pages/workshop/clients'),
	work: require('./pages/workshop/work'),
	contact: require('./pages/workshop/contact'),
	origins: require('./pages/workshop/origins'),
};


var bodyClass = function () {
	var body = document.body;
	var classesToRemove = [];

	Array.prototype.forEach.call(body.classList, function (className) {
		if (/^page-/.test(className)) {
			classesToRemove.push(className);
		}
	});
	classesToRemove.forEach(function (className) {
		body.classList.remove(className);
	});
	location.pathname.replace(/^\//, '').replace(/#.*$/, '').split('/')
		.forEach(function (urlFragment) {
			body.classList.add('page-' + urlFragment);
		});
};

module.exports = Router.extend({

	execute: function (callback, args) {
		app.trigger('navigate', null);
		bodyClass();
		if (callback) callback.apply(this, args);
	},

	/* eslint-disable quote-props */
	routes: {
		'': 'default',
		'home': 'home',
		'vision': 'vision',
		'workshop(/:page)': 'workshop',
		'workshop/': 'workshop',
		'workshop': 'workshop',
		'(*path)': 'catchAll',
	},
	/* eslint-enable quote-props */

	// ------- ROUTE HANDLERS ---------

	'default': function () {
		this.redirectTo('/home');
	},

	home: function () {
		app.trigger('page', new HomePage());
	},

	vision: function () {
		app.trigger('page', new VisionPage());
	},

	workshop: function (page) {
		if (page in workshopPages) {
			// This page always renders a subview
			var subview = new workshopPages[page]();

			if (app.state.mainView instanceof WorkshopPage) {
				app.state.mainView.trigger('subview', subview);
			} else {
				app.trigger('page', new WorkshopPage({
					subview: subview,
				}));
			}
		} else if (page === null) {
			this.redirectTo('/workshop/services');
		} else {
			app.trigger('page', new NotFoundPage());
		}
	},

	catchAll: function () {
		app.trigger('page', new NotFoundPage());
	},
});
