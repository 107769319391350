var assign = require('lodash.assign');
var app = require('ampersand-app');
var Menu = require('../models/menu');
var View = require('ampersand-view');


module.exports = View.extend({
	setMenu: function (menu) {
		if (!menu) menu = {};
		if (this.menu) {
			menu = assign({}, this.menu, menu);
		}
		if (this.parent && typeof this.parent.setMenu === 'function') {
			this.parent.setMenu(menu);
		} else {
			var newMenu = new Menu(menu);

			app.state.menu.set(
				newMenu.getAttributes({ props: true }));
		}
	},
});
