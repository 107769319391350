var Modernizr = require('./Modernizr.js');
var modElem = require('./modElem.js');
  var mStyle = {
    style: modElem.elem.style
  };

  // kill ref for gc, must happen before mod.elem is removed, so we unshift on to
  // the front of the queue.
  Modernizr._q.unshift(function() {
    delete mStyle.style;
  });

  module.exports = mStyle;

