var recaptchaLoader = require('../helpers/recaptcha');
var View = require('ampersand-view');
var dom = require('ampersand-dom');
var matchesSelector = require('matches-selector');

var slice = Array.prototype.slice;

var getMatches = function (el, selector) {
	if (selector === '') return [el];
	var matches = [];

	if (matchesSelector(el, selector)) matches.push(el);

	return matches.concat(slice.call(el.querySelectorAll(selector)));
};

var recaptcha;


module.exports = View.extend({

	template: [
		/* eslint-disable indent */
		'<label>',
			'<span data-hook="label"></span>',
			'<input class="form-input">',
			'<div data-hook="message-container">',
				'<p data-hook="message-text"></p>',
			'</div>',
		'</label>',
		/* eslint-enable indent */
	].join(''),

	bindings: {
		/* eslint-disable quote-props */
		'label': [
			{
				hook: 'label',
			},
			{
				type: 'toggle',
				hook: 'label',
			},
		],
		'message': {
			type: 'text',
			hook: 'message-text',
		},
		'showMessage': {
			type: 'toggle',
			hook: 'message-container',
		},
		/* eslint-enable quote-props */
	},

	props: {
		value: {
			type: 'string',
			required: true,
			'default': null,
			allowNull: true,
		},
		name: 'string',
		label: ['string', true, ''],
		required: ['boolean', true, true],
		message: ['string', true, ''],
		requiredMessage: ['string', true, 'Please prove you\'re human'],
		validClass: ['string', true, 'input-valid'],
		invalidClass: ['string', true, 'input-invalid'],
		validityClassSelector: ['string', true, '.form__captcha'],
		shouldValidate: ['boolean', true, false],
	},

	derived: {
		valid: {
			cache: false,
			deps: ['value'],
			fn: function () {
				return this.value !== null;
			},
		},
		showMessage: {
			deps: ['message', 'shouldValidate'],
			fn: function () {
				return this.shouldValidate && this.message;
			},
		},
		changed: {
			deps: ['value'],
			fn: function () {
				return this.value !== null;
			},
		},
		validityClass: {
			deps: ['valid', 'validClass', 'invalidClass', 'shouldValidate'],
			fn: function () {
				if (!this.shouldValidate) {
					return '';
				}

				return this.valid ? this.validClass : this.invalidClass;

			},
		},
	},

	initialize: function (spec) {
		spec = spec || {};

		this.callbackName = '__recaptcha_callback-' +
			Date.now() + '-' +
			parseInt(Math.random() * 100000, 10) +
			'__';
		window[this.callbackName] = this.callback.bind(this);

		this.expiredCallbackName = '__recaptcha_expired_callback-' +
			Date.now() + '-' +
			parseInt(Math.random() * 100000, 10) +
			'__';
		window[this.expiredCallbackName] = this.expiredCallback.bind(this);

		this.on('change:valid change:value', this.reportToParent, this);
		this.on('change:validityClass', this.validityClassChanged, this);

		this.message = this.requiredMessage;
		if (spec.template) this.template = spec.template;

		this.once('remove', this.cleanup, this);
	},

	cleanup: function () {
		if (typeof window[this.callbackName] !== 'undefined') {
			delete window[this.callbackName];
		}
		if (typeof window[this.expiredCallbackName] !== 'undefined') {
			delete window[this.expiredCallbackName];
		}
	},

	render: function () {
		var that = this;

		this.renderWithTemplate(this);

		this.input = this.query('input');
		var captchaContainer = document.createElement('div');

		captchaContainer.classList.add('form__captcha');
		this.input.parentNode.replaceChild(captchaContainer, this.input);
		this.input = captchaContainer;

		recaptchaLoader.load(function (localRecaptcha) {
			recaptcha = localRecaptcha;
			that.recaptchaWidget = recaptcha.render(that.input, {
				sitekey: '6LdIoBYUAAAAAOuYIQOs65Mav4DZV-JN0AYYu1K_',
				size: 'compact',
				theme: 'dark',
				callback: that.callbackName,
				'expired-callback': that.expiredCallbackName,
			});
		});

		return this;
	},

	callback: function (response) {
		this.set({ value: response });
	},

	expiredCallback: function () {
		this.set({ value: null });
	},

	reset: function () {
		if (this.recaptchaWidget) {
			recaptcha.reset(this.recaptchaWidget);
		}
		this.set({ value: null });
	},

	clear: function () {
		this.reset();
	},

	validityClassChanged: function (view, newClass) {
		var oldClass = view.previousAttributes().validityClass;

		getMatches(this.el, this.validityClassSelector)
			.forEach(function (match) {
				dom.switchClass(match, oldClass, newClass);
			});
	},

	reportToParent: function () {
		if (this.parent) this.parent.update(this);
	},

	beforeSubmit: function () {
		this.shouldValidate = true;
	},
});
