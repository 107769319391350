/* eslint-disable */
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex#Polyfill
if (!Array.prototype.findIndex) {
	Object.defineProperty(Array.prototype, 'findIndex', {
		value: function(predicate) {
			// 1. Let O be ? ToObject(this value).
			if (this == null) {
				throw new TypeError('"this" is null or not defined');
			}

			var o = Object(this);

			// 2. Let len be ? ToLength(? Get(O, "length")).
			var len = o.length >>> 0;

			// 3. If IsCallable(predicate) is false, throw a TypeError exception.
			if (typeof predicate !== 'function') {
				throw new TypeError('predicate must be a function');
			}

			// 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
			var thisArg = arguments[1];

			// 5. Let k be 0.
			var k = 0;

			// 6. Repeat, while k < len
			while (k < len) {
				// a. Let Pk be ! ToString(k).
				// b. Let kValue be ? Get(O, Pk).
				// c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
				// d. If testResult is true, return k.
				var kValue = o[k];
				if (predicate.call(thisArg, kValue, k, o)) {
					return k;
				}
				// e. Increase k by 1.
				k++;
			}

			// 7. Return -1.
			return -1;
		}
	});
}
/* eslint-enable */

var result = require('lodash.result');
var debounce = require('lodash.debounce');
var Hammer = require('hammerjs');
var app = require('ampersand-app');
var dom = require('ampersand-dom');
var ViewSwitcher = require('../../helpers/view-switcher');
var PageView = require('../../views/base');
var transitions = require('../../helpers/transitions');
var visitor = require('../../helpers/visitor');
var preloader = require('../../helpers/preloader');
var templates = require('../../templates');

var workshopPages = [
	require('./services'),
	require('./clients'),
	require('./work'),
	require('./contact'),
	require('./origins'),
];

module.exports = PageView.extend({
	pageTitle: 'Workshop',

	template: templates.pages.workshop.index,

	assets: {
		fonts: preloader.common.fonts.concat([
			'A Love of Thunder',
		]),
		images: preloader.common.images.concat([
			'/videos/services-background-poster.jpg',
			'/videos/origins-background-poster.jpg',
			'/videos/work-background-poster.jpg',
			'/images/text-texture-tile.png',
			'/images/noise-tile.png',
		]),
	},

	autoRender: false,

	initialize: function (options) {
		var that = this;

		// this marks the correct nav item selected
		this.on('subview', function (view) {
			that.handleNewSubview(view);
			visitor.pageview({
				title: view.pageTitle,
			});
		});

		this.render(options.subview);

		this.listenTo(app, 'galleryStart', this.unbindEvents.bind(this));
		this.listenTo(app, 'galleryClose', this.bindEvents.bind(this));

		this.once('remove', this.cleanup, this);
	},

	cleanup: function () {
		this.viewSwitcher.clear();
		this.unbindEvents();
		dom.removeClass(document.body, 'section-first');
		dom.removeClass(document.body, 'section-last');
	},

	menu: {
		logo: false,
		workshop: false,
		contact: false,
		hint: true,
	},

	render: function (subview) {
		var that = this;

		// main renderer
		this.renderWithTemplate(this);

		// init and configure our page switcher
		var subviewContainer = this.queryByHook('subview-container');

		this.viewSwitcher = new ViewSwitcher(
			subviewContainer, {
				parent: this,
				show: function (newView) {
					// it's inserted and rendered for me
					document.title = result(newView, 'pageTitle') +
						' — Envisioneers' ||
						'Envisioneers';
					subviewContainer.scrollTop = 0;

					var oldView = that.mainSubview;
					var oldIndex = that.findViewIndex(oldView);
					var newIndex = that.findViewIndex(newView);

					if (oldIndex !== -1) {
						if (oldIndex < newIndex) {
							transitions.fadeInFromBottom(newView.el);
						} else {
							transitions.fadeInFromTop(newView.el);
						}
					}

					if (!newView.viewSwitcher && !newView.sectionSwitcher) {
						that.setMenu(newView.menu);
					}

					that.mainSubview = newView;
				},
				hide: function (oldView, newView, callback) {
					var oldIndex = that.findViewIndex(oldView);
					var newIndex = that.findViewIndex(newView);

					if (oldIndex < newIndex) {
						transitions.fadeOutToTop(oldView.el, callback);
					} else {
						transitions.fadeOutToBottom(oldView.el, callback);
					}
				},
			}
		);

		if (subview && subview instanceof PageView) {
			this.handleNewSubview(subview);
		}

		this.subviewPaths = [];
		this.queryAll('[data-hook~="menu"] a').forEach(function (a) {
			that.subviewPaths.push(a.getAttribute('href').replace(/^\//, ''));
		});

		this.bindEvents();

		return this;
	},

	bindEvents: function () {
		var that = this;

		// Define touch events
		this.mc = new Hammer.Manager(this.el, {
			touchAction: 'auto',
			recognizers: [
				[Hammer.Swipe, { direction: Hammer.DIRECTION_VERTICAL }],
			],
		});
		this.mc.on('swipedown', function (e) {
			if (e.pointerType === 'touch') {
				that.prev(e);
			}
		});
		this.mc.on('swipeup', function (e) {
			if (e.pointerType === 'touch') {
				that.next(e);
			}
		});

		// Listen to scroll events
		this.boundScroll = this.scroll.bind(this);
		this.el.addEventListener('wheel', this.boundScroll);

		// Listen to keyboard events
		this.boundKeyPress = this.keyPress.bind(this);
		window.addEventListener('keydown', this.boundKeyPress);
	},

	unbindEvents: function () {
		this.mc.destroy();
		this.el.removeEventListener('wheel', this.boundScroll);
		window.removeEventListener('keydown', this.boundKeyPress);
	},

	scroll: debounce(function (e) {
		/* eslint-disable no-invalid-this */
		if (e.deltaY > 0) {
			this.next(e);
		}
		if (e.deltaY < 0) {
			this.prev(e);
		}
		/* eslint-enable no-invalid-this */
	}, 300, true),

	keyPress: function (e) {
		if (e.defaultPrevented) {
			return;
		}

		if (e.target instanceof HTMLInputElement ||
				e.target instanceof HTMLTextAreaElement) {
			return;
		}

		switch (e.key) {
			case 'ArrowUp':
				this.prev(e);
				break;
			case 'ArrowDown':
				this.next(e);
				break;
			case 'Backspace':
				app.navigate('/');
				break;
			default:
				var number = Number(e.key);

				if (isNaN(number)) return;

				var targetPath = this.subviewPaths[number - 1];

				if (targetPath) {
					app.navigate(targetPath);
				}
		}

		e.preventDefault();
	},

	prev: function () {
		if (typeof this.mainSubview.sectionIndex !== 'undefined' &&
				this.mainSubview.sectionIndex !== 0) {
			return;
		}

		var index = this.subviewPaths.findIndex(function (path) {
			return path === app.state.path;
		});

		if (index !== -1 && index > 0) {
			app.navigate('/' + this.subviewPaths[index - 1]);
		}
	},

	next: function () {
		if (typeof this.mainSubview.sectionIndex !== 'undefined' &&
				this.mainSubview.sectionIndex !== 0) {
			return;
		}

		var index = this.subviewPaths.findIndex(function (path) {
			return path === app.state.path;
		});

		if (index !== -1 && index < this.subviewPaths.length - 1) {
			app.navigate('/' + this.subviewPaths[index + 1]);
		}
	},

	handleNewSubview: function (subview) {
		// tell the view switcher to render the new one
		this.viewSwitcher.set(subview);

		// mark the correct nav item selected
		app.updateActiveNav();
	},

	findViewIndex: function (view) {
		return workshopPages.findIndex(function (View) {
			return view instanceof View;
		});
	},
});
