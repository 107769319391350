var WorkshopSubview = require('./workshop-subview');
var templates = require('../../templates');


module.exports = WorkshopSubview.extend({
	pageTitle: 'Our clients',
	template: templates.pages.workshop.clients,
	autoCycle: true,
	sectionSelectors: {
		mobile: '[data-hook~="m-section"]',
		tablet: '> section',
	},
});
