/* eslint-disable */
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex#Polyfill
if (!Array.prototype.findIndex) {
	Object.defineProperty(Array.prototype, 'findIndex', {
		value: function(predicate) {
			// 1. Let O be ? ToObject(this value).
			if (this == null) {
				throw new TypeError('"this" is null or not defined');
			}

			var o = Object(this);

			// 2. Let len be ? ToLength(? Get(O, "length")).
			var len = o.length >>> 0;

			// 3. If IsCallable(predicate) is false, throw a TypeError exception.
			if (typeof predicate !== 'function') {
				throw new TypeError('predicate must be a function');
			}

			// 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
			var thisArg = arguments[1];

			// 5. Let k be 0.
			var k = 0;

			// 6. Repeat, while k < len
			while (k < len) {
				// a. Let Pk be ! ToString(k).
				// b. Let kValue be ? Get(O, Pk).
				// c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
				// d. If testResult is true, return k.
				var kValue = o[k];
				if (predicate.call(thisArg, kValue, k, o)) {
					return k;
				}
				// e. Increase k by 1.
				k++;
			}

			// 7. Return -1.
			return -1;
		}
	});
}
/* eslint-enable */

/* eslint-disable */
(function (ElementProto) {
	if (typeof ElementProto.matches !== 'function') {
		ElementProto.matches = ElementProto.msMatchesSelector || ElementProto.mozMatchesSelector || ElementProto.webkitMatchesSelector || function matches(selector) {
			var element = this;
			var elements = (element.document || element.ownerDocument).querySelectorAll(selector);
			var index = 0;

			while (elements[index] && elements[index] !== element) {
				++index;
			}

			return Boolean(elements[index]);
		};
	}

	if (typeof ElementProto.closest !== 'function') {
		ElementProto.closest = function closest(selector) {
			var element = this;

			while (element && element.nodeType === 1) {
				if (element.matches(selector)) {
					return element;
				}

				element = element.parentNode;
			}

			return null;
		};
	}
})(window.Element.prototype);
/* eslint-enable */

var app = require('ampersand-app');
var dom = require('ampersand-dom');
var xhr = require('xhr');
var debounce = require('lodash.debounce');
var Hammer = require('hammerjs');
var snap = require('snapsvg');
var View = require('ampersand-view');
var Collection = require('ampersand-collection');
var State = require('ampersand-state');
var transitions = require('../helpers/transitions');
var templates = require('../templates');

var GalleryItem = State.extend({
	props: {
		el: 'object',
		caption: 'string',
	},
});

var GalleryItems = Collection.extend({
	model: GalleryItem,
});

var GalleryItemView = View.extend({
	template: templates.includes.galleryItem,
	bindings: {
		'model.caption': {
			type: 'text',
			hook: 'caption',
		},
	},
	initialize: function () {
		this.once('remove', this.cleanup, this);
	},
	cleanup: function () {
		dom.removeClass(this.el, 'is-playing');
	},
	render: function () {
		this.renderWithTemplate(this);

		var placeholder = this.queryByHook('placeholder');

		placeholder.parentNode.replaceChild(this.model.el, placeholder);

		if (this.model.el instanceof HTMLVideoElement) {
			var video = this.model.el;

			video.tabIndex = 0;

			// Set up some event listeners
			video.addEventListener('play', this.videoPlaying.bind(this));
			video.addEventListener('pause', this.videoStopped.bind(this));
			video.addEventListener('ended', this.videoStopped.bind(this));
			video.addEventListener('emptied', this.videoStopped.bind(this));
			video.addEventListener('ended', video.load);
			video.addEventListener('seeked', video.play);

			video.closest('figure').addEventListener('click',
				this.playOrPause.bind(video));
			video.closest('figure').addEventListener('keydown',
				this.playOrPause.bind(video));

			// Add the play button
			xhr({
				uri: '/images/play.svg',
				responseType: 'document',
			}, function (err, resp) {
				if (err) return;
				if (resp.statusCode >= 400) return;

				var svg = document.importNode(resp.body.documentElement, true);

				svg.classList.add('gallery__play');

				video.closest('figure').appendChild(svg);
			});
		}

		return this;
	},
	videoStopped: function (e) {
		dom.removeClass(this.el, 'is-playing');
		e.target.controls = false;
	},
	videoPlaying: function (e) {
		dom.addClass(this.el, 'is-playing');
		e.target.controls = true;
	},
	playOrPause: function (e) {
		if (e instanceof KeyboardEvent) {
			if (e.key !== 'Enter') return;
			e.stopPropagation();
		}
		if (this.paused || this.ended) {
			this.play();
		} else {
			this.pause();
		}
	},
});


module.exports = View.extend({

	autoRender: false,

	template: templates.includes.gallery,

	events: {
		'click [data-hook~="close"]': 'close',
		'keydown [data-hook~="close"]': 'close',
		'click [data-hook~="prev"]': 'prev',
		'keydown [data-hook~="prev"]': 'prev',
		'click [data-hook~="next"]': 'next',
		'keydown [data-hook~="next"]': 'next',
		'mouseover [data-hook~="prev"]': 'navLeftEnter',
		'focus [data-hook~="prev"]': 'navLeftEnter',
		'mouseover [data-hook~="next"]': 'navRightEnter',
		'focus [data-hook~="next"]': 'navRightEnter',
		'mouseout [data-hook~="prev"]': 'navLeftLeave',
		'blur [data-hook~="prev"]': 'navLeftLeave',
		'mouseup [data-hook~="prev"]': 'navLeftLeave',
		'touchend [data-hook~="prev"]': 'navLeftLeave',
		'mouseout [data-hook~="next"]': 'navRightLeave',
		'blur [data-hook~="next"]': 'navRightLeave',
		'mouseup [data-hook~="next"]': 'navRightLeave',
		'touchend [data-hook~="next"]': 'navRightLeave',
	},

	initialize: function (data) {
		var that = this;

		this.originalEvent = data.e;

		this.collection = new GalleryItems(
			Array.prototype.map.call(data.items, function (el) {
				var clone = el.cloneNode(true);

				clone.tabIndex = -1;
				clone.className = '';

				if (el === data.e.target) {
					that.initial = clone;
				}

				return {
					el: clone,
					caption: el
						.closest('figure')
						.querySelector('figcaption')
						.textContent,
				};
			}));

		this.render();

		this.once('remove', this.cleanup, this);
	},

	cleanup: function () {
		this.unbindEvents();
		dom.removeClass(document.body, 'gallery-active');
	},

	render: function () {
		this.renderWithTemplate(this);
		this.renderCollection(this.collection, GalleryItemView,
			this.queryByHook('gallery-items'));

		this.initViewer();
		this.bindEvents();

		dom.addClass(document.body, 'gallery-active');
		this.listenTo(app, 'galleryClose', function () {
			dom.removeClass(document.body, 'gallery-active');
		});

		return this;
	},

	bindEvents: function () {
		var that = this;

		// Define touch events
		this.mc = new Hammer.Manager(this.el, {
			touchAction: 'auto',
			recognizers: [
				[Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }],
			],
		});
		this.mc.on('swipeleft', function (e) {
			if (e.pointerType === 'touch') {
				that.next(e);
			}
		});
		this.mc.on('swiperight', function (e) {
			if (e.pointerType === 'touch') {
				that.prev(e);
			}
		});

		// Listen to scroll events
		this.boundScroll = this.scroll.bind(this);
		this.el.addEventListener('wheel', this.boundScroll);

		// Listen to keyboard events
		this.boundKeyPress = this.keyPress.bind(this);
		window.addEventListener('keydown', this.boundKeyPress);
	},

	unbindEvents: function () {
		this.mc.destroy();
		this.el.removeEventListener('wheel', this.boundScroll);
		window.removeEventListener('keydown', this.boundKeyPress);
	},

	scroll: debounce(function (e) {
		/* eslint-disable no-invalid-this */
		if (e.deltaX > 0 || e.deltaY > 0) {
			this.next(e);
		}
		if (e.deltaX < 0 || e.deltaY < 0) {
			this.prev(e);
		}
		/* eslint-enable no-invalid-this */
	}, 300, true),

	keyPress: function (e) {
		if (e.defaultPrevented) {
			return;
		}

		if (e.target instanceof HTMLInputElement ||
				e.target instanceof HTMLTextAreaElement) {
			return;
		}

		switch (e.key) {
			case 'ArrowUp':
			case 'ArrowLeft':
			case 'PageUp':
				this.prev();
				break;
			case 'ArrowDown':
			case 'ArrowRight':
			case 'PageDown':
				this.next();
				break;
			case 'Home':
				this.goTo(0);
				break;
			case 'End':
				this.goTo(this.collection.length - 1);
				break;
			case 'Escape':
				app.trigger('galleryClose');
				break;
			default:
				return;
		}
		e.stopPropagation();
		e.preventDefault();
	},

	initViewer: function () {
		if (!this.initial) {
			this.initial = this.originalEvent.currentTarget
				.querySelector('img, video');
		}
		if (!this.initial) {
			this.initial = this.el.querySelector('img, video');
		}
		if (!this.initial) {
			app.trigger('galleryClose');
		}
		this.index = this.findIndex(this.initial);
		this.show(this.index);
	},

	findIndex: function (el) {
		return this.collection.serialize().findIndex(function (model) {
			return model.el.src === el.src;
		});
	},

	findSlideElem: function (el) {
		return el.closest('[data-hook~="item"]');
	},

	close: function (e) {
		if (e instanceof KeyboardEvent) {
			if (e.key !== 'Enter') {
				return;
			}
			e.stopPropagation();
		}
		app.trigger('galleryClose');
	},

	goTo: function (index) {
		var oldIndex = this.index;

		this.index = index;
		this.show(this.index, oldIndex);
	},

	prev: function (e) {
		var oldIndex = this.index;

		if (e instanceof Event) {
			if (e instanceof KeyboardEvent && e.key !== 'Enter') return;
			e.preventDefault();
		}

		if (this.index > 0) {
			this.index -= 1;
		} else {
			this.index = this.collection.length - 1;
		}
		this.show(this.index, oldIndex, 'backward');
	},

	next: function (e) {
		var oldIndex = this.index;

		if (e instanceof Event) {
			if (e instanceof KeyboardEvent && e.key !== 'Enter') return;
			e.preventDefault();
		}

		if (this.index < this.collection.length - 1) {
			this.index += 1;
		} else {
			this.index = 0;
		}
		this.show(this.index, oldIndex, 'forward');
	},

	showCallback: function (slide) {
		slide.style.opacity = null;
		slide.style.left = null;
	},

	hideCallback: function (slide) {
		dom.removeClass(slide, 'is-active');
		slide.style.opacity = null;
		slide.style.left = null;
	},

	show: function (index, oldIndex, movement) {
		var that = this;

		if (index === oldIndex) return;

		if (!movement && typeof oldIndex !== 'undefined') {
			if (index > oldIndex) {
				movement = 'forward';
			} else {
				movement = 'backward';
			}
		}

		this.collection.forEach(function (item, i) {
			var slide = that.findSlideElem(item.el);

			if (i === index) {
				dom.addClass(slide, 'is-active');
				if (!movement) {
					that.showCallback(slide);
					if (item.el instanceof HTMLVideoElement) {
						item.el.play();
					}
				} else if (movement === 'forward') {
					transitions.absoluteFadeInFromRight(slide,
						function () {
							that.showCallback(slide);
						});
				} else {
					transitions.absoluteFadeInFromLeft(slide, function () {
						that.showCallback(slide);
					});
				}
				app.trigger('galleryView', item.el);
			} else if (movement && i === oldIndex) {
				if (item.el instanceof HTMLVideoElement) {
					item.el.load();
				}
				if (movement === 'forward') {
					transitions.fadeOutToLeft(slide, function () {
						that.hideCallback(slide);
					});
				} else {
					transitions.fadeOutToRight(slide, function () {
						that.hideCallback(slide);
					});
				}
			}
		});
	},

	navEnter: function (e, el) {
		var s = snap(el);
		var path = s.select('[data-hook~="path"]');
		var hoverPath = s.select('[data-hook~="hover"]');

		path.animate(
			{ d: hoverPath.attr('d') },
			transitions.DURATION * 1000,
			transitions.EASEIN.getRatio
		);

		dom.addClass(e.target, 'is-active');
	},

	navLeave: function (e, el) {
		var s = snap(el);
		var path = s.select('[data-hook~="path"]');
		var restPath = s.select('[data-hook~="rest"]');

		path.animate(
			{ d: restPath.attr('d') },
			transitions.DURATION * 1000,
			transitions.EASEOUT.getRatio
		);

		dom.removeClass(e.target, 'is-active');
	},

	navLeftEnter: function (e) {
		this.navEnter(e, this.queryByHook('nav-left'));
	},

	navLeftLeave: function (e) {
		this.navLeave(e, this.queryByHook('nav-left'));
	},

	navRightEnter: function (e) {
		this.navEnter(e, this.queryByHook('nav-right'));
	},

	navRightLeave: function (e) {
		this.navLeave(e, this.queryByHook('nav-right'));
	},
});
