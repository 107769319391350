/* globals ga */
var assign = require('lodash.assign');

/* eslint-disable max-len, newline-after-var, quotes */
var script = document.createElement('script');
script.innerHTML = [
	"(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){",
	"(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),",
	"m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)",
	"})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');",
].join('');
document.head.appendChild(script);
/* eslint-enable max-len, newline-after-var, quotes */

ga('create', 'UA-93494848-1', 'auto');

module.exports = {
	pageview: function (options) {
		ga('send', assign({
			hitType: 'pageview',
			location: location.protocol + '//' + location.host +
				location.pathname + location.hash,
			title: document.title,
		}, options));
	},
	event: function (options) {
		ga('send', assign({
			hitType: 'event',
		}, options));
	},
};
