var dom = require('ampersand-dom');
var View = require('ampersand-view');
var gsap = require('gsap');
var transitions = require('../helpers/transitions');
var templates = require('../templates');


var duration = transitions.DURATION * 4;
var delay = duration * 3;

module.exports = View.extend({
	template: templates.includes.loader,

	initialize: function () {
		this.once('remove', this.cleanup, this);
	},

	cleanup: function () {
		if (this.timeout) clearTimeout(this.timeout);
		if (this.timeline) this.timeline.kill();
		if (this.rotation) this.rotation.kill();
		if (this.rotationTween) this.rotationTween.kill();
	},

	render: function () {
		this.renderWithTemplate(this);

		this.body = this.queryByHook('body');
		this.covers = this.queryAllByHook('cover');
		this.verticals = this.queryAllByHook('vertical');
		this.horizontals = this.queryAllByHook('horizontal');
		this.contents = this.queryAllByHook('content');

		this.rotation = gsap.TweenMax.to(this.queryByHook('cog'), 2, {
			rotation: 360,
			repeat: -1,
			ease: gsap.Linear.easeIn,
		});

		this.rotationTween = gsap.TweenMax.fromTo(this.rotation, 4, {
			timeScale: 1.25,
		}, {
			timeScale: 0.75,
			repeat: -1,
			yoyo: true,
			ease: gsap.Sine.easeOut,
		});

		this.timeline = new gsap.TimelineMax({
			paused: true,
			autoRemoveChildren: true,
		});

		this.addIntro();

		this.timeout = setTimeout(this.play.bind(this),
			transitions.DURATION * 1000);

		return this;
	},

	play: function () {
		this.timeline.play();
	},

	addIntro: function () {
		var that = this;

		this.timeline
			.addLabel('intro')
			.add([
				gsap.TweenMax.to(this.verticals, duration, {
					height: '100%',
					ease: transitions.EASEIN,
				}),
				gsap.TweenMax.to(this.horizontals, duration / 2, {
					width: '100%',
					marginLeft: '-50%',
					delay: duration / 2,
					ease: transitions.EASEIN,
					onComplete: function () {
						that.timeline.removeLabel('intro');
						that.timeline.removeLabel('revealing');
						that.timeline.removeLabel('open');
						that.timeline.removeLabel('covering');
						that.timeline.removeLabel('closed');
						that.nextContent();
					},
				}),
			]);
	},

	addOutro: function () {
		this.timeline.add([
			gsap.TweenMax.to(this.horizontals, duration / 2, {
				width: 0,
				marginLeft: 0,
				ease: transitions.EASEOUT,
			}),
			gsap.TweenMax.to(this.verticals, duration, {
				height: 0,
				ease: transitions.EASEOUT,
				onComplete: this.cb,
			}),
		]);
	},

	nextContentActive: function () {
		var index = this.getCurrentContentIndex();

		if (typeof this.previousContentIndex === 'undefined') {
			switch (index) {
				case -1:
					dom.addClass(this.contents[0], 'is-active');
					break;
				case 0:
					dom.removeClass(this.contents[0], 'is-active');
					dom.addClass(this.contents[1], 'is-active');
					break;
				default:
					dom.removeClass(this.contents[1], 'is-active');
					dom.addClass(this.contents[0], 'is-active');
					this.previousContentIndex = index;
					break;
			}
		} else if (index === 0) {
			var nextContentIndex =
				this.previousContentIndex < this.contents.length - 1
				? this.previousContentIndex + 1
				: 1;

			dom.removeClass(this.contents[0], 'is-active');
			dom.addClass(this.contents[nextContentIndex], 'is-active');
			this.previousContentIndex = nextContentIndex;
		} else {
			dom.removeClass(this.contents[this.previousContentIndex],
				'is-active');
			dom.addClass(this.contents[0],
				'is-active');
		}
	},

	getCurrentContentIndex: function () {
		return this.contents.indexOf(
			this.query('[data-hook~="content"].is-active'));
	},

	addReveal: function () {
		var index = this.getCurrentContentIndex();
		var bodyHeight = this.body.offsetHeight;
		var contentHeight = this.contents[index].offsetHeight;
		var differenceRatio = (bodyHeight - contentHeight) / bodyHeight;

		return gsap.TweenMax.to(this.covers, duration, {
			height: differenceRatio * 100 / 2 + '%',
			ease: transitions.EASEOUT,
		});
	},

	addCover: function () {
		var that = this;

		return gsap.TweenMax.to(this.covers, duration, {
			height: '50%',
			ease: transitions.EASEIN,
			onComplete: function () {
				if (!that.ending) {
					that.nextContent();
				}
			},
		});
	},

	nextContent: function () {
		var that = this;

		this.nextContentActive();
		this.timeline
			.addLabel('revealing', '+=' +
				this.getCurrentContentIndex() === -1 ? 0 : duration)
			.add(this.addReveal(), 'revealing')
			.addLabel('open')
			.addLabel('covering', '+=' + (function () {
				switch (that.getCurrentContentIndex()) {
					case -1:
						// Fall through
					case 0:
						return delay / 2;
					default:
						return delay;
				}
			})())
			.add(this.addCover(), 'covering')
			.addLabel('closed');
	},

	close: function (cb) {
		var that = this;

		this.ending = true;
		this.cb = cb;
		this.addOutro();
		this.timeline.timeScale(2);
		switch (this.timeline.currentLabel()) {
			case 'intro':
				this.timeline.addCallback(this.cb, 0);
				this.timeline.reverse();
				break;
			case 'revealing':
				this.timeline.addCallback(function () {
					that.timeline
						.seek('closed')
						.play();
				}, 0);
				this.timeline.reverse();
				break;
			case 'open':
				this.timeline.seek('covering');
				break;
		}
	},
});
