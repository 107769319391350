var Promise = require('bluebird');
var PxLoader = require('./PxLoader');

require('./PxLoaderImage');
require('./PxLoaderVideo');
require('./PxLoaderFont');

var loadAssets = function (assets, resolve, reject) {

	var loader = new PxLoader();

	if (assets.images) {
		assets.images.forEach(function (src) {
			loader.addImage(src);
		});
	}
	if (assets.videos) {
		assets.videos.forEach(function (src) {
			loader.addVideo(src);
		});
	}
	if (assets.fonts) {
		assets.fonts.forEach(function (family) {
			loader.addFont(family);
		});
	}

	loader.addCompletionListener(function (e) {
		if (e.error || e.timeout) {
			reject();
		} else if (e.loaded) {
			resolve();
		} else {
			reject();
		}
	});

	loader.start();
};

var preloader = function (assets) {
	return new Promise(loadAssets.bind(null, assets));
};

preloader.common = {
	fonts: [
		'Dooodleista',
	],
	images: [
		'/images/logo-tablet.svg',
		'/images/logo-mobile.svg',
		'/images/grain-blurred.png',
		'/images/grain-crisp.png',
	],
};

module.exports = preloader;
