/* eslint-disable */
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex#Polyfill
if (!Array.prototype.findIndex) {
	Object.defineProperty(Array.prototype, 'findIndex', {
		value: function(predicate) {
			// 1. Let O be ? ToObject(this value).
			if (this == null) {
				throw new TypeError('"this" is null or not defined');
			}

			var o = Object(this);

			// 2. Let len be ? ToLength(? Get(O, "length")).
			var len = o.length >>> 0;

			// 3. If IsCallable(predicate) is false, throw a TypeError exception.
			if (typeof predicate !== 'function') {
				throw new TypeError('predicate must be a function');
			}

			// 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
			var thisArg = arguments[1];

			// 5. Let k be 0.
			var k = 0;

			// 6. Repeat, while k < len
			while (k < len) {
				// a. Let Pk be ! ToString(k).
				// b. Let kValue be ? Get(O, Pk).
				// c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
				// d. If testResult is true, return k.
				var kValue = o[k];
				if (predicate.call(thisArg, kValue, k, o)) {
					return k;
				}
				// e. Increase k by 1.
				k++;
			}

			// 7. Return -1.
			return -1;
		}
	});
}
/* eslint-enable */

var assign = require('lodash.assign');
var debounce = require('lodash.debounce');
var Hammer = require('hammerjs');
var app = require('ampersand-app');
var dom = require('ampersand-dom');
var Collection = require('ampersand-collection');
var State = require('ampersand-state');
var Menu = require('../models/menu');
var View = require('ampersand-view');
var snap = require('snapsvg');
var transitions = require('../helpers/transitions');
var templates = require('../templates');

var SectionNavIndexItemView = View.extend({
	template: templates.includes.sectionNavIndexItem,
});

var Section = State.extend({
	props: {
		el: 'object',
		index: 'number',
	},
});

var Sections = Collection.extend({
	model: Section,
});


module.exports = View.extend({

	template: templates.includes.sectionNav,

	events: {
		'click [data-hook~="prev"]': 'prev',
		'keydown [data-hook~="prev"]': 'prev',
		'click [data-hook~="next"]': 'next',
		'keydown [data-hook~="next"]': 'next',
		'click [data-hook~="index"] > li': 'goTo',
		'keydown [data-hook~="index"] > li': 'goToOnEnter',
		'mouseover [data-hook~="prev"]': 'navLeftEnter',
		'focus [data-hook~="prev"]': 'navLeftEnter',
		'mouseover [data-hook~="next"]': 'navRightEnter',
		'focus [data-hook~="next"]': 'navRightEnter',
		'mouseout [data-hook~="prev"]': 'navLeftLeave',
		'blur [data-hook~="prev"]': 'navLeftLeave',
		'mouseup [data-hook~="prev"]': 'navLeftLeave',
		'touchend [data-hook~="prev"]': 'navLeftLeave',
		'mouseout [data-hook~="next"]': 'navRightLeave',
		'blur [data-hook~="next"]': 'navRightLeave',
		'mouseup [data-hook~="next"]': 'navRightLeave',
		'touchend [data-hook~="next"]': 'navRightLeave',
	},

	initialize: function (options) {
		var that = this;

		var defaults = {
			selector: '> section',
			selectors: {},
		};

		options = assign({}, defaults, options || {});

		/* Create cascade of selectors for each layout:
		 *
		 * Each inheriting from the previous one if they weren’t explicitly
		 * provided when this view was instantiated.
		 */
		var previousSize = 'mobile';

		if (!options.selectors.mobile) {
			options.selectors.mobile = options.selector;
		}
		for (var size in app.sizes) {
			if (!options.selectors[size]) {
				options.selectors[size] = options.selectors[previousSize];
			}
			previousSize = size;
		}
		this.options = options;

		this.listenTo(app, 'galleryStart', this.unbindEvents.bind(this));
		this.listenTo(app, 'galleryClose', this.bindEvents.bind(this));
		this.listenTo(app, 'galleryView', function (el) {
			var index = that.collection.serialize()
				.findIndex(function (section) {
					return section.el.querySelector(
						el.tagName.toLowerCase() +
						'[src="' + el.getAttribute('src') + '"]');
				});

			if (index !== -1) {
				that.goTo(index);
			}
		});

		this.once('remove', this.cleanup, this);
	},

	cleanup: function () {
		this.unbindEvents();
		window.removeEventListener('orientationchange',
			this.boundBuildSectionViewer);
		window.removeEventListener('optimizedResize',
			this.boundBuildSectionViewer);
		this.cancelCycle();
	},

	render: function () {
		this.renderWithTemplate(this);
		this.initSectionViewer();
		this.bindEvents();

		return this;
	},

	bindEvents: function () {
		var that = this;

		// Define touch events
		this.mc = new Hammer.Manager(this.parent.el, {
			touchAction: 'auto',
			recognizers: [
				[Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }],
			],
		});
		this.mc.on('swipeleft', function (e) {
			if (e.pointerType === 'touch') {
				that.next(e);
			}
		});
		this.mc.on('swiperight', function (e) {
			if (e.pointerType === 'touch') {
				that.prev(e);
			}
		});

		// Listen to scroll events
		this.boundScroll = this.scroll.bind(this);
		this.parent.el.addEventListener('wheel', this.boundScroll);

		// Listen to keyboard events
		this.boundKeyPress = this.keyPress.bind(this);
		window.addEventListener('keydown', this.boundKeyPress);
	},

	unbindEvents: function () {
		this.mc.destroy();
		this.parent.el.removeEventListener('wheel', this.boundScroll);
		window.removeEventListener('keydown', this.boundKeyPress);
	},

	scroll: debounce(function (e) {
		/* eslint-disable no-invalid-this */
		if (e.deltaX > 0) {
			this.next(e);
		}
		if (e.deltaX < 0) {
			this.prev(e);
		}
		/* eslint-enable no-invalid-this */
	}, 300, true),

	keyPress: function (e) {
		if (e.defaultPrevented) {
			return;
		}

		if (e.target instanceof HTMLInputElement ||
				e.target instanceof HTMLTextAreaElement) {
			return;
		}

		switch (e.key) {
			case 'ArrowLeft':
			case 'PageUp':
				this.prev();
				break;
			case 'ArrowRight':
			case 'PageDown':
				this.next();
				break;
			case 'Home':
				this.goTo(0);
				break;
			case 'End':
				this.goTo(this.collection.length - 1);
				break;
			default:
				return;
		}

		this.cancelCycle();
		e.stopPropagation();
		e.preventDefault();
	},

	initSectionViewer: function () {
		this.boundBuildSectionViewer = this.buildSectionViewer.bind(this);
		this.boundBuildSectionViewer();
		window.addEventListener('orientationchange',
			this.boundBuildSectionViewer);
		window.addEventListener('optimizedResize',
			this.boundBuildSectionViewer);

		if (this.parent.autoCycle) {
			this.cycleId = setTimeout(this.cycle.bind(this), 3000);
		}
	},

	cycle: function () {
		this.cycleId = setInterval(this.next.bind(this), 3000);
	},

	cancelCycle: function () {
		if (typeof this.cycleId === 'number') {
			clearTimeout(this.cycleId);
		}
	},

	buildSectionViewer: function () {
		var currentSize = app.findSize();

		// If the sizes AND selectors are different
		if (currentSize !== this.currentSize &&
				this.options.selectors[currentSize] !==
				this.options.selectors[this.currentSize]) {

			// Clean up
			if (this.collectionView) {
				this.collection.forEach(this.hideCallback);
				this.collectionView.remove();
			}

			this.createCollection(this.options.selectors[currentSize]);

			if (this.collection.length > 1) {
				this.collectionView = this.renderCollection(this.collection,
					SectionNavIndexItemView, this.queryByHook('index'));
				this.showHint(true);
				this.index = this.findInitialIndex();
				this.show(this.index);
			} else {
				this.index = undefined;
				this.showHint(false);
				if (typeof this.parent.setMenu === 'function') {
					this.parent.setMenu(this.parent.menu);
				}
			}
			this.currentSize = currentSize;
		}
	},

	findInitialIndex: function () {
		var hash = location.hash.replace(/^#/, '');
		var index = -1;

		if (hash) {
			index = this.collection.serialize().findIndex(function (section) {
				return section.el.querySelector('#' + hash) ||
					section.el.getAttribute('id') === hash;
			});
		}

		return index === -1 ? 0 : index;
	},

	showHint: function (show) {
		var hint = this.queryByHook('hint');

		switch (show) {
			case true:
				dom.addClass(hint, 'is-active');
				break;
			case false:
				dom.removeClass(hint, 'is-active');
				break;
			default:
				throw new TypeError('Boolean expected, got %o', show);
		}
	},

	createCollection: function (selector) {
		this.collection = new Sections(
			Array.prototype.slice.call(this.parent.el.querySelectorAll(
				'[data-hook~="section-container"] ' + selector))
			.map(function (section, index) {
				return new Section({
					el: section,
					index: index,
				});
			})
		);
	},

	goToOnEnter: function (e) {
		if (e.key === 'Enter') {
			this.goTo(e);
		}
	},

	goTo: function (e) {
		var index;
		var oldIndex = this.index;

		if (e instanceof Event) {
			index = Number(e.delegateTarget.dataset.index);
			e.preventDefault();
			this.cancelCycle();
		} else if (e && e.pointerType === 'touch') {
			this.cancelCycle();
		} else {
			index = e;
		}

		this.index = index;
		this.show(this.index, oldIndex);
		if (location.hash.replace(/^#/, '')) {
			history.replaceState(undefined, undefined, '#');
		}
	},

	prev: function (e) {
		var oldIndex = this.index;

		if (e instanceof Event) {
			if (e instanceof KeyboardEvent && e.key !== 'Enter') return;
			e.preventDefault();
			this.cancelCycle();
		} else if (e && e.pointerType === 'touch') {
			this.cancelCycle();
		}

		if (this.index > 0) {
			this.index -= 1;
		} else {
			this.index = this.collection.length - 1;
		}
		this.show(this.index, oldIndex, 'backward');
		if (location.hash.replace(/^#/, '')) {
			history.replaceState(undefined, undefined, '#');
		}
	},

	next: function (e) {
		var oldIndex = this.index;

		if (e instanceof Event) {
			if (e instanceof KeyboardEvent && e.key !== 'Enter') return;
			e.preventDefault();
			this.cancelCycle();
		} else if (e && e.pointerType === 'touch') {
			this.cancelCycle();
		}

		if (this.index < this.collection.length - 1) {
			this.index += 1;
		} else {
			this.index = 0;
		}
		this.show(this.index, oldIndex, 'forward');
		if (location.hash.replace(/^#/, '')) {
			history.replaceState(undefined, undefined, '#');
		}
	},

	showCallback: function (section) {
		var input = section.el.querySelector('input');

		if (input) {
			input.focus();
		}
		section.el.style.opacity = null;
		section.el.style.left = null;
	},

	hideCallback: function (section) {
		dom.removeClass(section.el, 'is-active');
		section.el.style.opacity = null;
		section.el.style.left = null;
	},

	show: function (index, oldIndex, movement) {
		var that = this;

		if (index === oldIndex) return;

		if (!movement && typeof oldIndex !== 'undefined') {
			if (index > oldIndex) {
				movement = 'forward';
			} else {
				movement = 'backward';
			}
		}

		this.collection.forEach(function (section, i) {
			if (i === index) {
				that.setMenu(section.el);
				dom.addClass(section.el, 'is-active');
				if (!movement) {
					that.showCallback(section);
				} else if (movement === 'forward') {
					transitions.absoluteFadeInFromRight(section.el,
						function () {
							that.showCallback(section);
						});
				} else {
					transitions.absoluteFadeInFromLeft(section.el, function () {
						that.showCallback(section);
					});
				}
			} else if (movement && i === oldIndex) {
				if (movement === 'forward') {
					transitions.fadeOutToLeft(section.el, function () {
						that.hideCallback(section);
					});
				} else {
					transitions.fadeOutToRight(section.el, function () {
						that.hideCallback(section);
					});
				}
			}
		});
		this.updateIndex();
		this.parent.trigger('view:change', index);
	},

	updateIndex: function () {
		var that = this;

		this.collection.forEach(function (section) {
			var indexItemEl = that.queryAll('[data-index="' +
				section.index + '"]')[0];

			if (indexItemEl) {
				if (section.index === that.index) {
					dom.addClass(indexItemEl, 'is-active');
				} else {
					dom.removeClass(indexItemEl, 'is-active');
				}
			}
		});
	},

	navEnter: function (e, el) {
		var s = snap(el);
		var path = s.select('[data-hook~="path"]');
		var hoverPath = s.select('[data-hook~="hover"]');

		path.animate(
			{ d: hoverPath.attr('d') },
			transitions.DURATION * 1000,
			transitions.EASEIN.getRatio
		);

		dom.addClass(e.target, 'is-active');
	},

	navLeave: function (e, el) {
		var s = snap(el);
		var path = s.select('[data-hook~="path"]');
		var restPath = s.select('[data-hook~="rest"]');

		path.animate(
			{ d: restPath.attr('d') },
			transitions.DURATION * 1000,
			transitions.EASEOUT.getRatio
		);

		dom.removeClass(e.target, 'is-active');
	},

	navLeftEnter: function (e) {
		this.navEnter(e, this.queryByHook('nav-left'));
	},

	navLeftLeave: function (e) {
		this.navLeave(e, this.queryByHook('nav-left'));
	},

	navRightEnter: function (e) {
		this.navEnter(e, this.queryByHook('nav-right'));
	},

	navRightLeave: function (e) {
		this.navLeave(e, this.queryByHook('nav-right'));
	},

	setMenu: function (el) {
		var menu = {};

		if (this.index === 0) {
			dom.addClass(document.body, 'section-first');
		} else {
			menu.hint = false;
			menu.contact = false;
			menu.main = false;
			dom.removeClass(document.body, 'section-first');
		}

		var menuEl = el.querySelector('[data-hook~="menu"]');

		if (menuEl) {
			menu = assign({}, menu, JSON.parse(menuEl.innerHTML));
		}
		if (this.parent.menu) {
			menu = assign({}, this.parent.menu, menu);
		}
		if (typeof this.parent.setMenu === 'function') {
			this.parent.setMenu(menu);
		} else {
			var newMenu = new Menu(menu);

			app.state.menu.set(
				newMenu.getAttributes({ props: true }));
		}
	},

});
