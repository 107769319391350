var debounce = require('lodash.debounce');
var assign = require('lodash.assign');
var Hammer = require('hammerjs');
var dom = require('ampersand-dom');
var app = require('ampersand-app');
var Collection = require('ampersand-collection');
var State = require('ampersand-state');
var Menu = require('../models/menu');
var View = require('../views/base');
var gsap = require('gsap');
var transitions = require('../helpers/transitions');
var visitor = require('../helpers/visitor');
var preloader = require('../helpers/preloader');
var templates = require('../templates');

var VisionNavIndexItemView = View.extend({
	template: templates.includes.visionNavIndexItem,
});

var Section = State.extend({
	props: {
		el: 'object',
		index: 'number',
	},
});

var Sections = Collection.extend({
	model: Section,
});


module.exports = View.extend({

	pageTitle: 'Our Vision',

	template: templates.pages.vision,

	assets: {
		fonts: preloader.common.fonts.concat([
			'A Love of Thunder',
		]),
		videos: [
			'/videos/vision_04.mp4',
		],
		images: preloader.common.images.concat([
			'/videos/vision_04-poster.jpg',
			'/images/vision/bulb-on.jpg',
			'/images/vision/bulb-off.jpg',
			'/images/vision/diver.jpg',
			'/images/vision/diver-background.jpg',
			'/images/vision/diver-alpha-mask.png',
			'/images/vision/plane.jpg',
			'/images/vision/plane-background.jpg',
			'/images/vision/plane-alpha-mask.png',
			'/images/text-texture-tile.png',
			'/images/noise-tile.png',
		]),
	},

	events: {
		'click [data-hook~="index"] > li': 'goTo',
		'keydown [data-hook~="index"] > li': 'goToOnEnter',
	},

	initialize: function () {
		this.autoNext = true;
		this.once('remove', this.cleanup, this);
	},

	cleanup: function () {
		this.unbindEvents();
		clearTimeout(this.timer);
		this.currentAnimation.kill();

		dom.removeClass(document.body, 'page-1');
		dom.removeClass(document.body, 'page-2');
		dom.removeClass(document.body, 'page-3');
		dom.removeClass(document.body, 'page-4');
	},

	menu: {
		logo: false,
		vision: false,
		contact: false,
	},

	render: function () {
		var that = this;

		this.renderWithTemplate(this);
		this.createPageAnimations();
		this.initSectionViewer();
		this.bindEvents();

		this.queryByHook('video').playbackRate = 0;

		this.listenTo(app, 'mobilemenu', function (active) {
			if (active) {
				that.currentAnimation.pause();
			} else {
				that.currentAnimation.play();
			}
		});

		return this;
	},

	bindEvents: function () {
		var that = this;

		// Define touch events
		this.mc = new Hammer.Manager(this.el, {
			touchAction: 'auto',
			recognizers: [
				[Hammer.Swipe, { direction: Hammer.DIRECTION_VERTICAL }],
			],
		});
		this.mc.on('swipeup', function (e) {
			if (e.pointerType === 'touch') {
				that.next(e);
			}
		});
		this.mc.on('swipedown', function (e) {
			if (e.pointerType === 'touch') {
				that.prev(e);
			}
		});

		// Listen to scroll events
		this.boundScroll = this.scroll.bind(this);
		this.el.addEventListener('wheel', this.boundScroll);

		// Listen to keyboard events
		this.boundKeyPress = this.keyPress.bind(this);
		window.addEventListener('keydown', this.boundKeyPress);
	},

	unbindEvents: function () {
		this.mc.destroy();
		this.el.removeEventListener('wheel', this.boundScroll);
		window.removeEventListener('keydown', this.boundKeyPress);
	},

	scroll: debounce(function (e) {
		/* eslint-disable no-invalid-this */
		if (e.deltaY > 0) {
			this.next(e);
		}
		if (e.deltaY < 0) {
			this.prev(e);
		}
		/* eslint-enable no-invalid-this */
	}, 300, true),

	keyPress: function (e) {
		if (e.defaultPrevented) {
			return;
		}

		if (e.target instanceof HTMLInputElement ||
				e.target instanceof HTMLTextAreaElement) {
			return;
		}

		switch (e.key) {
			case 'ArrowUp':
			case 'PageUp':
				this.prev(e);
				break;
			case 'ArrowDown':
			case 'PageDown':
				this.next(e);
				break;
			case 'Home':
				this.goTo(0);
				break;
			case 'End':
				this.goTo(this.collection.length - 1);
				break;
			case 'Backspace':
				app.navigate('/');
				break;
			default:
				var number = Number(e.key);

				if (isNaN(number)) return;
				if (number > this.collection.length) return;

				this.goTo(number - 1);
		}

		e.preventDefault();
	},

	initSectionViewer: function () {
		this.createCollection();

		if (this.collection.length > 1) {
			this.collectionView = this.renderCollection(this.collection,
				VisionNavIndexItemView, this.queryByHook('index'));
			this.index = 0;
			this.show(this.index);
		} else {
			this.index = undefined;
			this.menu.hint = false;
			this.setMenu(this.menu);
		}
	},

	createCollection: function () {
		this.collection = new Sections(
			Array.prototype.slice.call(this.el.querySelectorAll(
				'[data-hook~="page"]'))
			.map(function (section, index) {
				return new Section({
					el: section,
					index: index,
				});
			})
		);
	},

	goToOnEnter: function (e) {
		if (e.key === 'Enter') {
			this.goTo(e);
		}
	},

	goTo: function (e) {
		var index;
		var oldIndex = this.index;

		if (e instanceof Event) {
			this.autoNext = false;
			index = Number(e.delegateTarget.dataset.index);
			e.preventDefault();
		} else {
			index = e;
		}

		this.index = index;
		this.show(this.index, oldIndex);
	},

	prev: function (e) {
		var oldIndex = this.index;

		if (e instanceof Event) {
			this.autoNext = false;
			e.preventDefault();
		}

		if (this.index > 0) {
			this.index -= 1;
		} else {
			return;
		}
		this.show(this.index, oldIndex);
	},

	next: function (e) {
		var oldIndex = this.index;

		if (e instanceof Event) {
			this.autoNext = false;
			e.preventDefault();
		}

		if (this.index < this.collection.length - 1) {
			this.index += 1;
		} else {
			return;
		}
		this.show(this.index, oldIndex);
	},

	nextAnimation: function () {
		if (this.autoNext) {
			this.next();
		}
	},

	showCallback: function (section, index) {
		var input = section.el.querySelector('input');

		if (input) {
			input.focus();
		}
		section.el.style.opacity = null;
		section.el.style.top = null;

		this.startPageAnimation(index);
	},

	hideCallback: function (section, index) {
		dom.removeClass(document.body, 'page-' + (index + 1));
		dom.removeClass(section.el, 'is-active');
		section.el.style.opacity = null;
		section.el.style.top = null;

		this.pageAnimations[index].pause().seek(0);
		Array.prototype.forEach.call(
			section.el.querySelectorAll('*'),
			function (el) {
				el.style = null;
			}
		);
	},

	show: function (index, oldIndex) {
		var that = this;
		var movement = false;

		if (index === oldIndex) return;

		if (typeof oldIndex !== 'undefined') {
			if (index > oldIndex) {
				movement = 'forward';
			} else {
				movement = 'backward';
			}
		}

		visitor.event({
			eventCategory: 'View',
			eventAction: 'Switch',
			eventLabel: 'Index: ' + index,
		});

		this.collection.forEach(function (section, i) {
			if (i === index) {
				that.setMenu(section.el);
				dom.addClass(document.body, 'page-' + (index + 1));
				dom.addClass(section.el, 'is-active');
				if (!movement) {
					that.showCallback(section, index);
				} else if (movement === 'forward') {
					gsap.TweenMax.fromTo(section.el, transitions.DURATION * 2, {
						opacity: 0,
						top: '10%',
					}, {
						opacity: 1,
						top: '0',
						ease: transitions.EASEIN,
						onComplete: function () {
							that.showCallback(section, i);
						},
					});
				} else {
					gsap.TweenMax.fromTo(section.el, transitions.DURATION * 2, {
						opacity: 0,
						top: '-10%',
					}, {
						opacity: 1,
						top: '0',
						ease: transitions.EASEIN,
						onComplete: function () {
							that.showCallback(section, i);
						},
					});
				}
			} else if (movement && i === oldIndex) {
				if (movement === 'forward') {
					gsap.TweenMax.to(section.el, transitions.DURATION * 2, {
						opacity: 0,
						top: '-=10%',
						ease: transitions.EASEOUT,
						onComplete: function () {
							that.hideCallback(section, i);
						},
					});
				} else {
					gsap.TweenMax.to(section.el, transitions.DURATION * 2, {
						opacity: 0,
						top: '+=10%',
						ease: transitions.EASEOUT,
						onComplete: function () {
							that.hideCallback(section, i);
						},
					});
				}
			}
		});
		this.updateIndex();
	},

	updateIndex: function () {
		var that = this;

		this.collection.forEach(function (section) {
			var indexItemEl = that.queryAll('[data-index="' +
				section.index + '"]')[0];

			if (indexItemEl) {
				if (section.index === that.index) {
					dom.addClass(indexItemEl, 'is-active');
				} else {
					dom.removeClass(indexItemEl, 'is-active');
				}
			}
		});
	},

	setMenu: function (el) {
		var menu = {};

		if (this.index === this.collection.length - 1) {
			menu.contact = true;
		}

		var menuEl = el.querySelector('[data-hook~="menu"]');

		if (menuEl) {
			menu = JSON.parse(menuEl.innerHTML);
		}
		if (this.menu) {
			menu = assign({}, this.menu, menu);
		}
		if (this.parent.menu) {
			menu = assign({}, this.parent.menu, menu);
		}
		if (typeof this.parent.setMenu === 'function') {
			this.parent.setMenu(menu);
		} else {
			var newMenu = new Menu(menu);

			app.state.menu.set(
				newMenu.getAttributes({ props: true }));
		}
	},

	createPageAnimations: function () {
		var that = this;
		var duration = transitions.DURATION * 6;
		var delay = duration * 2;
		var pages = this.el.querySelectorAll('[data-hook="page"]');

		this.pageAnimations = [];

		/* eslint-disable newline-per-chained-call, max-len */

		this.pageAnimations.push(new gsap.TimelineMax({
			paused: true,
			onComplete: function () {
				that.timer = setTimeout(that.nextAnimation.bind(that), delay * 1000);
			},
		}).to(pages[0].querySelector('[data-hook="title"]'), duration, {
			opacity: 1,
			delay: delay / 2,
			ease: gsap.Power2.easeIn,
		}).to(pages[0].querySelector('[data-hook="subtitle"]'), duration, {
			opacity: 1,
			delay: delay,
			ease: gsap.Power2.easeIn,
		}).to([
			pages[0].querySelector('[data-hook="title"]'),
			pages[0].querySelector('[data-hook="subtitle"]'),
		], duration / 2, {
			opacity: 0,
			delay: delay,
			ease: gsap.Power2.easeOut,
		}).to(pages[0].querySelector('[data-hook="overlay"]'), duration / 2, {
			opacity: 1,
			ease: gsap.RoughEase.ease.config({
				template: gsap.Power0.easeNone,
				strength: 10,
				points: 40,
				taper: 'in',
				randomize: true,
				clamp: true,
			}),
		}));

		this.pageAnimations.push(new gsap.TimelineMax({
			paused: true,
		}).to(pages[1].querySelector('[data-hook="diver"]'), duration * 2, {
			xPercent: 40,
			ease: gsap.Back.easeOut.config(1),
		}, 0).to(pages[1].querySelector('[data-hook="diver"]'), duration * 4, {
			xPercent: 35,
			ease: gsap.Sine.easeInOut,
			yoyo: true,
			repeat: -1,
		}, duration * 2).to(pages[1].querySelector('[data-hook="title"]'), duration, {
			opacity: 1,
			ease: gsap.Power2.easeIn,
			delay: duration,
		}, 0).to(pages[1].querySelector('[data-hook="subtitle"]'), duration, {
			opacity: 1,
			ease: gsap.Power2.easeIn,
			delay: duration * 3,
			onComplete: function () {
				that.timer = setTimeout(that.nextAnimation.bind(that), delay * 1000);
			},
		}, 0));

		this.pageAnimations.push(new gsap.TimelineMax({
			paused: true,
		}).to(pages[2].querySelector('[data-hook="title"]'), duration, {
			opacity: 1,
			delay: delay / 2,
			ease: gsap.Power2.easeIn,
		}).to(pages[2].querySelector('[data-hook="subtitle"]'), duration, {
			opacity: 1,
			delay: delay,
			ease: gsap.Power2.easeIn,
		}).to([
			pages[2].querySelector('[data-hook="title"]'),
			pages[2].querySelector('[data-hook="subtitle"]'),
		], duration / 2, {
			opacity: 0,
			delay: delay,
			ease: gsap.Power2.easeOut,
		}).addLabel('planeStart')
			.to(pages[2].querySelector('[data-hook="plane"]'), duration * 5, {
				xPercent: 140,
				scale: 0.2,
				ease: gsap.Power1.easeInOut,
				onComplete: this.nextAnimation.bind(this),
			}, 'planeStart')
			.to(pages[2].querySelector('[data-hook="plane"]'), duration * 5, {
				yPercent: -100,
				ease: gsap.Power3.easeIn,
			}, 'planeStart')
			.to(pages[2].querySelector('[data-hook="plane"]'), 0.05, {
				rotation: 0.25,
				yoyo: true,
				repeat: -1,
				ease: gsap.Sine.easeInOut,
			}, 'planeStart'));

		this.pageAnimations.push(new gsap.TimelineMax({
			paused: true,
		}).to(pages[3].querySelector('[data-hook="video"]'), 0, {
			playbackRate: 0,
		}).to(pages[3].querySelector('[data-hook="video"]'), duration, {
			playbackRate: 1,
			ease: gsap.Power2.easeIn,
		}).to(pages[3].querySelector('[data-hook="title"]'), duration, {
			opacity: 1,
			delay: delay / 2,
			ease: gsap.Power2.easeIn,
		}).to(pages[3].querySelector('[data-hook="subtitle"]'), duration, {
			opacity: 1,
			delay: delay,
			ease: gsap.Power2.easeIn,
		}));

		/* eslint-enable newline-per-chained-call, max-len */
	},

	startPageAnimation: function (index) {
		this.currentAnimation = this.pageAnimations[index];
		this.currentAnimation.play(0);
	},
});
