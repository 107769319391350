var platform = require('platform');
var assign = require('lodash.assign');
var app = require('ampersand-app');
var dom = require('ampersand-dom');
var xhr = require('xhr');
var View = require('ampersand-view');
var transitions = require('../helpers/transitions');
var gsap = require('gsap');
var snap = require('snapsvg');
var templates = require('../templates');


module.exports = View.extend({

	template: templates.includes.menu,

	events: {
		'click [data-hook~="logo"]': 'goHome',
		'keydown [data-hook~="logo"]': 'goHome',
		'click [data-hook~="toggle"]': 'toggleMobileMenu',
		'keydown [data-hook~="toggle"]': 'toggleMobileMenu',
		'mouseover a[href^="/vision"]': 'visionEnter',
		'focus a[href^="/vision"]': 'visionEnter',
		'mouseout a[href^="/vision"]': 'visionLeave',
		'blur a[href^="/vision"]': 'visionLeave',
		'mouseup a[href^="/vision"]': 'visionLeave',
		'touchend a[href^="/vision"]': 'visionLeave',
		'mouseover a[href^="/workshop"]': 'workshopEnter',
		'focus a[href^="/workshop"]': 'workshopEnter',
		'mouseout a[href^="/workshop"]': 'workshopLeave',
		'blur a[href^="/workshop"]': 'workshopLeave',
		'mouseup a[href^="/workshop"]': 'workshopLeave',
		'touchend a[href^="/workshop"]': 'workshopLeave',
	},

	initialize: function () {
		var that = this;

		this.transitionDuration = 0;
		this.once('remove', this.cleanup, this);

		this.listenTo(app, 'navigate', function () {
			if (that.mobileMenuActive) {
				that.toggleMobileMenu();
			}
		});
	},

	cleanup: function () {
		this.unbindEvents();
		window.removeEventListener('orientationchange',
			this.boundLoadLogo);
		window.removeEventListener('optimizedResize',
			this.boundLoadLogo);
		window.removeEventListener('orientationchange',
			this.boundAdjustMenu);
		window.removeEventListener('optimizedResize',
			this.boundAdjustMenu);
		window.removeEventListener('orientationchange',
			this.boundCloseMobileMenu);
		window.removeEventListener('optimizedResize',
			this.boundCloseMobileMenu);
		this.animateHintTimeline.kill();
	},

	render: function () {
		var that = this;

		this.renderWithTemplate(this);
		this.initLogo();

		// Store some references to the various elements
		this.container = this.el;
		this.vision = this.queryByHook('vision');
		this.workshop = this.queryByHook('workshop');
		this.hint = this.queryByHook('hint');
		this.hintLine = this.queryByHook('hint-line');
		this.main = this.queryByHook('main');
		this.contact = this.queryByHook('contact');
		this.toggle = this.queryByHook('toggle');
		this.lineTop = this.queryByHook('line-top');
		this.lineMiddle = this.queryByHook('line-middle');
		this.lineBottom = this.queryByHook('line-bottom');

		this.animateHint();

		// show/hide different views based on current path
		this.boundAdjustMenu = this.adjustMenu.bind(this, 0);
		this.model.on('change', function () {
			that.adjustMenu();
		});
		window.addEventListener('orientationchange',
			this.boundAdjustMenu);
		window.addEventListener('optimizedResize',
			this.boundAdjustMenu);

		// close the mobile menu if we make the screen too big
		this.boundCloseMobileMenu = this.closeMobileMenu.bind(this);
		window.addEventListener('orientationchange',
			this.boundCloseMobileMenu);
		window.addEventListener('optimizedResize',
			this.boundCloseMobileMenu);

		this.bindEvents();

		return this;
	},

	animateHint: function () {
		var y = Number(this.hintLine.getAttribute('y'));
		var height = Number(this.hintLine.getAttribute('height'));
		var duration = transitions.DURATION * 2;
		var delay = duration * 2;

		this.hintLine.setAttribute('height', 0);

		this.animateHintTimeline = new gsap.TimelineMax({
			repeat: -1,
		})
			.add(gsap.TweenMax.to(this.hintLine, duration, {
				attr: {
					height: height,
				},
				delay: delay,
				ease: transitions.EASEIN,
			}))
			.add(gsap.TweenMax.to(this.hintLine, duration, {
				attr: {
					y: y + height,
					height: 0,
				},
				delay: delay,
				ease: transitions.EASEOUT,
			}))
			.add(gsap.TweenMax.to(this.hintLine, duration, {
				attr: {
					y: y,
					height: height,
				},
				delay: delay,
				ease: transitions.EASEIN,
			}))
			.add(gsap.TweenMax.to(this.hintLine, duration, {
				attr: {
					height: 0,
				},
				delay: delay,
				ease: transitions.EASEOUT,
			}));
	},

	closeMobileMenu: function () {
		if (this.mobileMenuActive) {
			switch (app.findSize()) {
				case 'tablet':
				case 'desktop':
					this.toggleMobileMenu();
					break;
			}
		}
	},

	bindEvents: function () {
		// Listen to keyboard events
		this.boundKeyPress = this.keyPress.bind(this);
		window.addEventListener('keydown', this.boundKeyPress);
	},

	unbindEvents: function () {
		window.removeEventListener('keydown', this.boundKeyPress);
	},

	keyPress: function (e) {
		if (e.defaultPrevented) {
			return;
		}

		if (e.target instanceof HTMLInputElement ||
				e.target instanceof HTMLTextAreaElement) {
			return;
		}

		switch (e.key) {
			case 'Home':
				if (!e.ctrlKey) return;
				app.trigger('galleryClose');
				this.goHome();
				break;
			case 'Escape':
				if (!this.mobileMenuActive) return;
				this.toggleMobileMenu();
				break;
			default:
				return;
		}

		e.stopPropagation();
		e.preventDefault();
	},

	initLogo: function () {
		var that = this;

		this.logos = {};
		this.queryAllByHook('logo').forEach(function (logo) {
			that.logos[logo.dataset.size] = logo;
		});
		this.boundLoadLogo = this.loadLogo.bind(this);
		setTimeout(this.boundLoadLogo, 0);
		window.addEventListener('orientationchange',
			this.boundLoadLogo);
		window.addEventListener('optimizedResize',
			this.boundLoadLogo);
	},

	loadLogo: function () {
		var that = this;
		var currentSize = app.findSize();
		var logoSize = 'mobile';

		for (var size in app.sizes) {
			if (this.logos[size] &&
				app.sizes[size] <= app.sizes[currentSize]) {
				logoSize = size;
			}
		}
		this.logo = this.logos[logoSize];

		if (this.logo.hasChildNodes()) {
			this.adjustMenu(0);
		} else if (!this.loadLogo[logoSize + 'Loading']) {
			this.loadLogo[logoSize + 'Loading'] = true;
			xhr({
				uri: this.logo.dataset.src,
				responseType: 'document',
			}, function (err, resp) {
				that.loadLogo[logoSize + 'Loading'] = false;
				if (err) return;
				if (resp.statusCode >= 400) return;

				var svg = document.importNode(resp.body.documentElement, true);

				that.logo.appendChild(svg);
				that.adjustMenu(0);
			});
		}
	},

	setDuration: function (duration) {
		this.transitionDuration = duration;
	},

	adjustMenu: function (duration) {
		if (typeof duration === 'undefined') {
			this.setDuration(transitions.DURATION);
		} else {
			this.setDuration(duration);
		}

		this.expandLogo(this.model.logo);
		this.enableButton(this.vision, this.model.vision);
		this.enableButton(this.workshop, this.model.workshop);
		this.setLineTop(this.model.lineTop);
		this.setLineMiddle(this.model.lineMiddle);
		this.setLineBottom(this.model.lineBottom);
		if (this.adjustMenu.previousMain !== this.model.main) {
			this.showMain(this.model.main);
			this.adjustMenu.previousMain = this.model.main;
		}
		if (this.adjustMenu.previousToggle !== this.model.toggle) {
			this.showToggle(this.model.toggle);
			this.adjustMenu.previousToggle = this.model.toggle;
		}
		if (this.adjustMenu.previousHint !== this.model.hint) {
			this.showHint(this.model.hint);
			this.adjustMenu.previousHint = this.model.hint;
		}
		if (this.adjustMenu.previousContact !== this.model.contact) {
			this.showContact(this.model.contact);
			this.adjustMenu.previousContact = this.model.contact;
		}
	},

	enableButton: function (el, enable) {
		if (enable) {
			dom.removeClass(el, 'is-disabled');
		} else {
			dom.addClass(el, 'is-disabled');
		}
	},

	showMain: function (show) {
		var that = this;

		if (show) {
			dom.addClass(this.main, 'is-active');
			gsap.TweenMax.fromTo(this.main, this.transitionDuration, {
				opacity: 0,
				y: '20',
			}, {
				opacity: 1,
				y: '0',
				ease: transitions.EASEIN,
				onComplete: function () {
					that.main.style.opacity = null;
					that.main.style.transform = null;
				},
			});
		} else {
			dom.removeClass(this.main, 'is-active');
			gsap.TweenMax.to(this.main, this.transitionDuration, {
				opacity: 0,
				y: '20',
				ease: transitions.EASEOUT,
				onComplete: function () {
					that.main.style.transform = null;
				},
			});
		}
	},

	showToggle: function (show) {
		var that = this;

		if (show) {
			dom.addClass(this.toggle, 'is-active');
			gsap.TweenMax.fromTo(this.toggle, this.transitionDuration, {
				opacity: 0,
				y: '-20',
			}, {
				opacity: 1,
				y: '0',
				ease: transitions.EASEIN,
				onComplete: function () {
					that.main.style.opacity = null;
					that.main.style.transform = null;
				},
			});
		} else {
			gsap.TweenMax.to(this.toggle, this.transitionDuration, {
				opacity: 0,
				y: '-20',
				ease: transitions.EASEOUT,
				onComplete: function () {
					dom.removeClass(that.toggle, 'is-active');
					that.main.style.transform = null;
				},
			});
		}
	},

	showHint: function (show) {
		var that = this;

		if (show) {
			gsap.TweenMax.fromTo(this.hint, this.transitionDuration, {
				opacity: 0,
				y: '20',
			}, {
				opacity: 0.75,
				y: '0',
				ease: transitions.EASEIN,
				onComplete: function () {
					that.hint.style.opacity = null;
					that.hint.style.transform = null;
				},
			});
		} else {
			gsap.TweenMax.to(this.hint, this.transitionDuration, {
				opacity: 0,
				y: '20',
				ease: transitions.EASEOUT,
				onComplete: function () {
					that.hint.style.transform = null;
				},
			});
		}
	},

	showContact: function (show) {
		var that = this;

		if (show) {
			dom.addClass(this.contact, 'is-active');
			gsap.TweenMax.fromTo(this.contact, this.transitionDuration, {
				opacity: 0,
				y: '20',
			}, {
				opacity: 1,
				y: '0',
				ease: transitions.EASEIN,
				onComplete: function () {
					that.contact.style.opacity = null;
					that.contact.style.transform = null;
				},
			});
		} else {
			dom.removeClass(this.contact, 'is-active');
			gsap.TweenMax.to(this.contact, this.transitionDuration, {
				opacity: 0,
				y: '20',
				ease: transitions.EASEOUT,
				onComplete: function () {
					that.contact.style.transform = null;
				},
			});
		}
	},

	setLineTop: function (props) {
		var that = this;

		props = props || {};
		this.lineTop.style.top = props.top
			? props.top + 'vh' : null;
		this.lineTop.style.height = props.height
			? props.height + 'vh' : null;
		if (platform.name === 'Safari') {
			// Setting it directly on the SVG element because Safari
			// doesn’t change its size when the parent’s size is changed
			// even though its height is 100% already
			setTimeout(function () {
				that.lineTop.children[0].style.height = props.height
					? props.height + 'vh' : '100%';
			}, transitions.DURATION * 1000);
		}
	},

	setLineMiddle: function (props) {
		var that = this;

		props = props || {};
		this.lineMiddle.style.top = props.top
			? props.top + 'vh' : null;
		this.lineMiddle.style.height = props.height
			? props.height + 'vh' : null;
		if (platform.name === 'Safari') {
			// Setting it directly on the SVG element because Safari
			// doesn’t change its size when the parent’s size is changed
			// even though its height is 100% already
			setTimeout(function () {
				that.lineMiddle.children[0].style.height = props.height
					? props.height + 'vh' : '100%';
				that.lineMiddle.children[0].style.width = props.height
					? '2px' : 0;
			}, transitions.DURATION * 1000);
		}
	},

	setLineBottom: function (props) {
		var that = this;

		props = props || {};
		this.lineBottom.style.bottom = props.bottom
			? props.bottom + 'vh' : null;
		this.lineBottom.style.height = props.height
			? props.height + 'vh' : null;
		if (platform.name === 'Safari') {
			// Setting it directly on the SVG element because Safari
			// doesn’t change its size when the parent’s size is changed
			// even though its height is 100% already
			setTimeout(function () {
				that.lineMiddle.children[0].style.height = props.height
					? props.height + 'vh' : '100%';
			}, transitions.DURATION * 1000);
		}
	},

	expandLogo: function (expand) {
		if (!this.logo) return;

		var logoIcon = this.logo.querySelector('#logo');

		if (!logoIcon) return;

		var collapseProps, expandProps;

		switch (this.logo.dataset.size) {
			case 'mobile':
				collapseProps = {
					yPercent: -77,
				};
				expandProps = {
					yPercent: 0,
				};
				break;
			case 'tablet':
				collapseProps = {
					yPercent: -70,
				};
				expandProps = {
					yPercent: 0,
				};
				break;
		}

		if (expand) {
			this.logo.tabIndex = -1;

			gsap.TweenMax.to(this.logo, this.transitionDuration, assign(
				expandProps,
				{
					scale: 1,
					ease: transitions.EASEIN,
				}));
			gsap.TweenMax.to(logoIcon, this.transitionDuration, {
				opacity: 1,
				ease: transitions.EASEIN,
			});
			dom.removeClass(this.logo, 'is-collapsed');
		} else {
			this.logo.tabIndex = 0;

			gsap.TweenMax.to(this.logo, this.transitionDuration, assign(
				collapseProps,
				{
					scale: 0.7,
					ease: transitions.EASEOUT,
				}));
			gsap.TweenMax.to(logoIcon, this.transitionDuration, {
				opacity: 0,
				ease: transitions.EASEOUT,
			});
			dom.addClass(this.logo, 'is-collapsed');
		}
	},

	goHome: function (e) {

		if (e && e.type === 'keydown') {
			if (e.key === 'Enter') {
				e.stopPropagation();
			} else {
				return;
			}
		}

		app.navigate('/');
	},

	toggleMobileMenu: function (e) {
		if (e && e.type === 'keydown') {
			if (e.key === 'Enter') {
				e.stopPropagation();
			} else {
				return;
			}
		}

		var mobileMenu = this.queryByHook('mobile-menu');

		if (this.mobileMenuActive) {
			app.trigger('mobilemenu', false);

			gsap.TweenMax.to(this.toggle.querySelector('[data-hook="line-1"]'),
				transitions.DURATION, {
					rotation: 0,
					yPercent: 0,
					ease: transitions.EASEOUT,
				});
			gsap.TweenMax.to(this.toggle.querySelector('[data-hook="line-2"]'),
				transitions.DURATION, {
					opacity: 1,
					scaleX: 1,
					ease: transitions.EASEOUT,
				});
			gsap.TweenMax.to(this.toggle.querySelector('[data-hook="line-3"]'),
				transitions.DURATION, {
					rotation: 0,
					yPercent: 0,
					ease: transitions.EASEOUT,
				});

			transitions.fadeOutToLeft(mobileMenu, function () {
				dom.removeClass(mobileMenu, 'is-active');
				mobileMenu.style.opacity = null;
				mobileMenu.style.left = null;
			});
		} else {
			app.trigger('mobilemenu', true);
			gsap.TweenMax.to(this.toggle.querySelector('[data-hook="line-1"]'),
				transitions.DURATION, {
					rotation: 54,
					yPercent: 33,
					ease: transitions.EASEIN,
				});
			gsap.TweenMax.to(this.toggle.querySelector('[data-hook="line-2"]'),
				transitions.DURATION, {
					opacity: 0,
					scaleX: 0.5,
					ease: transitions.EASEIN,
				});
			gsap.TweenMax.to(this.toggle.querySelector('[data-hook="line-3"]'),
				transitions.DURATION, {
					rotation: -54,
					yPercent: -37,
					ease: transitions.EASEIN,
				});
			dom.addClass(mobileMenu, 'is-active');
			transitions.absoluteFadeInFromLeft(mobileMenu,
				function () {
					mobileMenu.style.opacity = null;
					mobileMenu.style.left = null;
				});
		}
		this.mobileMenuActive = !this.mobileMenuActive;
	},

	menuEnter: function (e, el) {
		var s = snap(el);
		var path = s.select('[data-hook~="path"]');
		var hoverPath = s.select('[data-hook~="hover"]');

		path.animate(
			{ d: hoverPath.attr('d') },
			transitions.DURATION * 1000,
			transitions.EASEIN.getRatio
		);

		dom.addClass(el.parentNode, 'is-active');
	},

	menuLeave: function (e, el) {
		var s = snap(el);
		var path = s.select('[data-hook~="path"]');
		var restPath = s.select('[data-hook~="rest"]');

		path.animate(
			{ d: restPath.attr('d') },
			transitions.DURATION * 1000,
			transitions.EASEOUT.getRatio
		);

		dom.removeClass(el.parentNode, 'is-active');
	},

	visionEnter: function (e) {
		this.menuEnter(e, this.vision.querySelector('svg'));
	},

	visionLeave: function (e) {
		this.menuLeave(e, this.vision.querySelector('svg'));
	},

	workshopEnter: function (e) {
		this.menuEnter(e, this.workshop.querySelector('svg'));
	},

	workshopLeave: function (e) {
		this.menuLeave(e, this.workshop.querySelector('svg'));
	},
});
