var app = require('ampersand-app');
var bind = require('lodash.bind');
var Router = require('./router');
var MainView = require('./views/main');
var domReady = require('domready');
var AppState = require('./models/app-state');


// Remove 300ms delay on mobile
require('hammer-timejs');

// attach our app to `window` so we can
// easily access it from the console.
window.app = app;

// Extends our main app singleton
app.extend({
	router: new Router(),
	// This is where it all starts
	init: function () {
		// Load & execute Modernizr
		require('./helpers/modernizr');

		// Load and initialize analytics
		require('./helpers/visitor');

		// Global state store
		app.state = new AppState();

		// Define widths and names for window sizes (`mobile` is the first,
		// assumed, size: a lá mobile-first min-width media queries approach
		// where the no-query case is the smallest size).
		// Please arrange from smallest to largest, the order matters.
		var gridMobileMargin = 16 * 2;

		this.sizes = {
			phablet: 560 + gridMobileMargin * 2,
			tablet: 720 + gridMobileMargin * 2,
			desktop: 960 + gridMobileMargin * 2,
		};

		// Create and attach our main view
		this.mainView = new MainView({
			model: this.me,
			el: document.body,
		});

		// this kicks off our backbutton tracking (browser history)
		// and will cause the first matching handler in the router
		// to fire.
		this.router.history.start({ pushState: true });
	},
	// This is a helper for navigating around the app.
	// this gets called by a global click handler that handles
	// all the <a> tags in the app.
	// it expects a url pathname for example: "/costello/settings"
	navigate: function (page) {
		var url = page.charAt(0) === '/' ? page.slice(1) : page;

		this.router.history.navigate(url, { trigger: true });
	},

	findSize: function () {
		var viewportWidth = window.innerWidth;
		var previousSize = 'mobile';

		for (var size in this.sizes) {
			if (viewportWidth < this.sizes[size]) {
				return previousSize;
			}
			previousSize = size;
		}

		return previousSize;
	},
});

// run it on domReady
domReady(bind(app.init, app));

// Create optimized event for listening to window resize
// eslint-disable-next-line max-len
// https://developer.mozilla.org/en-US/docs/Web/Events/resize#requestAnimationFrame_customEvent
(function () {
	var throttle = function (type, name, obj) {
		obj = obj || window;
		var running = false;
		var func = function () {
			if (running) {
				return;
			}
			running = true;
			requestAnimationFrame(function () {
				obj.dispatchEvent(new CustomEvent(name));
				running = false;
			});
		};

		obj.addEventListener(type, func);
	};

	/* init - you can init any event */
	throttle('resize', 'optimizedResize');
})();
