var AmpersandState = require('ampersand-state');
var Menu = require('./menu');


module.exports = AmpersandState.extend({
	props: {
		mainView: ['object'],
		path: ['string'],
	},
	children: {
		menu: Menu,
	},
});
