/* eslint-disable */
// @depends PxLoader.js
var WebFont = require('webfontloader');

// PxLoader plugin to load fonts
// PxLoader plugin to load CSS
(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['pxloader'], function (PxLoader) {
            return (root.PxLoaderFont = factory(PxLoader));
        });
    } else if (typeof module === 'object' && module.exports) {
        // Node. Does not work with strict CommonJS, but
        // only CommonJS-like environments that support module.exports,
        // like Node.
		module.exports = factory(require('./PxLoader'));
    } else {
        // Browser globals
        root.PxLoaderFont = factory(root.PxLoader);
    }
}(this, function (PxLoader) {
	function PxLoaderFont(fontFamily, tags, priority) {
		var self = this,
			loader = null;

		this.tags = tags;
		this.priority = priority;
		var webFontConfig = {
			custom: {
				families: [fontFamily]
			},
			active: function() {
				loader.onLoad(self);
			},
			inactive: function() {
				loader.onError(self);
			}
		};

		this.start = function(pxLoader) {
			// we need the loader ref so we can notify upon completion
			loader = pxLoader;

			WebFont.load(webFontConfig);
		};

		// returns a name for the resource that can be used in logging
		this.getName = function() {
			return fontFamily;
		};
	}

	// add a convenience method to PxLoader for adding a font
	PxLoader.prototype.addFont = function(fontFamily, tags, priority) {
		var fontLoader = new PxLoaderFont(fontFamily, tags, priority);
		this.add(fontLoader);
	};

	return PxLoaderFont;
}));
