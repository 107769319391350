var assign = require('lodash.assign');
var mapLoader = require('google-maps');
var dom = require('ampersand-dom');
var xhr = require('xhr');
var formUrlEncode = require('form-urlencoded');
var ViewSwitcher = require('../../helpers/view-switcher');
var View = require('../../views/base');
var FormView = require('ampersand-form-view');
var InputView = require('ampersand-input-view');
var CaptchaView = require('../../views/captcha');
var RadioGroup = require('../../views/radio-group');
var WorkshopSubview = require('./workshop-subview');
var transitions = require('../../helpers/transitions');
var visitor = require('../../helpers/visitor');
var templates = require('../../templates');

var successView = new View({
	template: templates.includes.formSuccess,
});

var google, InfoBox;

mapLoader.KEY = 'AIzaSyCuaUDrOs8kCvzWe8rgbeisWF3NDXD8gJ4';

assign(InputView.prototype, {
	handleTypeChange: function () {
		if (this.type === 'textarea' &&
				this.input.tagName.toLowerCase() !== 'textarea') {
			var parent = this.input.parentNode;
			var textarea = document.createElement('textarea');

			// Ampersand doesn’t copy over the class names by default
			textarea.className = this.input.className;
			parent.replaceChild(textarea, this.input);
			this.input = textarea;
			// eslint-disable-next-line no-underscore-dangle
			this._applyBindingsForKey('');
		} else {
			this.input.type = this.type;
		}
	},
});

var ContactForm = FormView.extend({
	autoAppend: false,
	disableForm: function () {
		Array.prototype.forEach.call(
			this.el.querySelectorAll('input, button, textarea, select'),
			function (elem) {
				elem.dataset.originallyDisabled = String(elem.disabled);
				elem.disabled = true;
			}
		);
	},
	enableForm: function () {
		Array.prototype.forEach.call(
			this.el.querySelectorAll('input, button, textarea, select'),
			function (elem) {
				switch (elem.dataset.originallyDisabled) {
					case 'true':
						elem.disabled = true;
						break;
					case 'false':
						elem.disabled = false;
						break;
					default:
						throw new Error("Invalid value '" +
								elem.dataset.originallyDisabled + "'");
				}
				delete elem.dataset.originallyDisabled;
			}
		);
	},
});

var ContactInputView = InputView.extend({
	/* eslint-disable quote-props */
	bindings: assign({}, {
		'required': {
			type: 'booleanClass',
			name: 'is-required',
		},
	}, InputView.prototype.bindings),
	/* eslint-enable quote-props */
	props: {
		containerEl: 'object',
	},
});
var ContactRadioGroup = RadioGroup.extend({
	/* eslint-disable quote-props */
	bindings: assign({}, {
		'required': {
			type: 'booleanClass',
			name: 'is-required',
		},
	}, RadioGroup.prototype.bindings),
	/* eslint-enable quote-props */
	props: {
		containerEl: 'object',
	},
});
var ContactCaptchaView = CaptchaView.extend({
	/* eslint-disable quote-props */
	bindings: assign({}, {
		'required': {
			type: 'booleanClass',
			name: 'is-required',
		},
	}, CaptchaView.prototype.bindings),
	/* eslint-enable quote-props */
	props: {
		containerEl: 'object',
	},
});


module.exports = WorkshopSubview.extend({

	pageTitle: 'Contact us',

	template: templates.pages.workshop.contact,

	render: function () {
		var that = this;

		// Call the parent view’s render because of the section
		// navigation. This includes the renderWithTemplate.
		WorkshopSubview.prototype.render.apply(this, arguments);

		this.mapContainer = this.queryByHook('map-container');

		// The Google maps API code should only be loaded once
		if (typeof google === 'undefined') {
			mapLoader.load(function (localGoogle) {
				google = localGoogle;
				// This needs the `google` object to be in scope when it is
				// instantiated
				InfoBox = require('google-maps-infobox');
				that.renderMap();
			});
		} else {
			// This needs a setTimeout 0 in order to render
			// eslint-disable-next-line max-len
			// https://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful#779785
			setTimeout(this.renderMap.bind(this), 0);
		}

		// init and configure our view switcher
		var subviewContainer = this.queryByHook('subview-container');

		this.viewSwitcher = new ViewSwitcher(
			subviewContainer, {
				parent: this,
				show: function (newView) {
					subviewContainer.scrollTop = 0;

					if (that.subView) {
						transitions.fadeIn(newView.el);
					}

					if (newView === successView) {
						visitor.event({
							eventCategory: 'View',
							eventAction: 'Switch',
							eventLabel: 'Form Success',
						});
					}

					// Start looping through frames
					that.initFigureFrames();

					that.subView = newView;
				},
				hide: function (oldView, newView, callback) {
					transitions.fadeOut(oldView.el, callback);
				},
			}
		);

		this.renderForm();

		return this;
	},

	renderMap: function () {
		var that = this;

		this.position = {
			lat: -26.2173,
			lng: 28.04648,
		};

		this.map = new google.maps.Map(this.mapContainer, {
			center: this.position,
			zoom: 17,
			gestureHandling: 'cooperative',
			scrollwheel: false,
			keyboardShortcuts: false,
			backgroundColor: '#2b2521',
			styles: [
				{
					elementType: 'geometry',
					stylers: [
						{
							color: '#2b2521',
						},
					],
				},
				{
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#c1c1c1',
						},
					],
				},
				{
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#212121',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#a2a2a2',
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'geometry',
					stylers: [
						{
							color: '#474747',
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'geometry.fill',
					stylers: [
						{
							color: '#373737',
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'geometry.stroke',
					stylers: [
						{
							color: '#212121',
						},
					],
				},
				{
					featureType: 'road.local',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#efb709',
						},
					],
				},
			],
		});

		this.marker = new google.maps.Marker({
			position: this.position,
			icon: '/images/map-icon.png',
		});
		if (this.sectionIndex === 0) {
			this.marker.setMap(this.map);
		}

		this.infobox = new InfoBox({
			boxClass: 'contact__mapInfobox',
			closeBoxURL: '/images/close.png',
			/* eslint-disable max-len */
			content: '<p>22 Roper Str, New Centre, Johannesburg, RSA</p>' +
				'<p><a href="tel:+27114939666">+27 11 493 9666/7</a></p>' +
				'<p><a href="mailto:info@envs.co.za">info@envs.co.za</a></p>' +
				'<p><a href="/workshop/contact">Book a meeting</a></p>' +
				'<ul><li><a href="https://wwww.facebook.com/envisioneers"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" version="1.1" y="0px" x="0px">' +
					'<path fill="#fff" clip-rule="evenodd" d="m0.333 0v24h23.334v-22.333l-23.334-1.667zm15.821 12.54c-1.024-0.009-2.05-0.019-3.069-0.028-0.009 0.007-0.011 0.008-0.012 0.01-0.002 0.002-0.002 0.004-0.003 0.006s-0.002 0.005-0.003 0.007v0.007 0.014c-0.001 0.005-0.001 0.01-0.001 0.015v0.014 8.426 0.015 0.007c-0.001 0.003-0.001 0.005-0.001 0.007s0 0.005 0 0.008c-0.002 0.002-0.002 0.004-0.004 0.006-0.051 0.013-3.097-0.197-3.202-0.223-0.038-2.753-0.075-5.509-0.113-8.272-0.182-0.008-0.361-0.004-0.539-0.006-0.178-0.003-0.357-0.003-0.536-0.004s-0.358-0.004-0.537-0.005-0.357 0-0.535 0c-0.017-0.061 0.086-2.963 0.107-3.066 0.337 0.004 0.676 0.007 1.014 0.011 0.34 0.005 0.679 0.011 1.02 0.016 0.004-0.021 0.008-0.034 0.009-0.048 0.014-0.397 0.028-0.795 0.043-1.193 0.023-0.612 0.043-1.225 0.071-1.836 0.009-0.218 0.064-0.429 0.126-0.636 0.244-0.81 0.687-1.482 1.369-1.991 0.437-0.325 0.926-0.539 1.451-0.675 0.387-0.1 0.78-0.153 1.179-0.175 0.238-0.013 0.476 0.012 0.712 0.023 0.264 0.013 0.527 0.033 0.791 0.051 0.279 0.019 0.559 0.038 0.838 0.058 0.008 0 0.014 0.003 0.029 0.008-0.027 0.94-0.055 1.879-0.084 2.831h-0.084c-0.675-0.008-1.349-0.018-2.022-0.027-0.243-0.002-0.465 0.067-0.666 0.201-0.239 0.159-0.374 0.388-0.417 0.669-0.015 0.095-0.019 0.192-0.018 0.288 0.003 0.583 0.01 1.164 0.013 1.747 0.001 0.2-0.007 0.4-0.012 0.6-0.001 0.026 0 0.052 0 0.09 1.116-0.009 2.226-0.018 3.333-0.028 0.013 0.056-0.22 2.952-0.247 3.078z" fill-rule="evenodd"></path>' +
				'</svg>' +
				'</a></li><li><a href="mailto:info@envs.co.za"><svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" version="1.1" y="0px" x="0px">' +
					'<g fill="#fff">' +
						'<path d="m22.667 1.167l-22.334-0.667v23h23.334l-1-22.333zm-19.418 17.858l-0.172-13.159 17.498-0.229 0.283 13.396-17.609-0.008z"></path>' +
						'<polygon points="18.41 7.533 5.181 7.594 11.93 13.535"></polygon>' +
						'<polygon points="11.954 15.4 4.839 9.735 4.938 17.264 18.985 17.352 18.889 9.918"></polygon>' +
					'</g>' +
				'</svg>' +
				'</a></li></ul>',
			/* eslint-enable max-len */
		});

		this.mapListener = google.maps.event.addListener(this.marker, 'click',
			function () {
				// eslint-disable-next-line no-invalid-this
				that.infobox.open(that.map, this);
			});
		if (this.sectionIndex === 0) {
			this.infobox.open(this.map, this.marker);
		}

		google.maps.event.addDomListener(window, 'resize',
			function () {
				that.map.setCenter(that.position);
			});
		google.maps.event.addDomListener(window, 'orientationchange',
			function () {
				that.map.setCenter(that.position);
			});

		this.on('view:change', function (index) {
			switch (index) {
				// Showing the map clearly
				case 0:
					that.marker.setMap(that.map);
					that.infobox.open(that.map, that.marker);
					that.map.setOptions({
						disableDefaultUI: false,
					});
					break;
				// Not showing the map clearly
				default:
					that.marker.setMap(null);
					that.infobox.close();
					that.map.setOptions({
						disableDefaultUI: true,
					});
			}
		});
	},

	renderForm: function () {
		var that = this;

		var contactFormContainer = new View({
			autoRender: false,
			template: templates.includes.contactForm,
		});

		this.viewSwitcher.set(contactFormContainer);
		this.registerSubview(contactFormContainer);

		var success = function () {
			visitor.event({
				eventCategory: 'Form',
				eventAction: 'Submission',
				eventLabel: 'Success',
			});
			that.viewSwitcher.set(successView);

			if (!successView.viewSwitcher) {
				that.setMenu(successView.menu);
			}
		};

		var contactForm = new ContactForm({
			el: contactFormContainer.queryByHook('form-container'),
			validCallback: function (valid) {
				var messageContainer =
					this.el.querySelector('[data-hook~="form-message"]');

				if (messageContainer) {
					messageContainer.innerHTML = '';
				}

				switch (valid) {
					case true:
						dom.removeClass(this.el, 'has-error');
						break;
					case false:
						dom.addClass(this.el, 'has-error');
						break;
				}
			},
			submitCallback: function (data) {
				var that = this;

				this.disableForm();

				xhr({
					body: formUrlEncode(data),
					method: 'POST',
					uri: '/submit.php',
					headers: {
						'Content-type': 'application/x-www-form-urlencoded',
					},
				}, function (err, resp) {
					if (err) {
						// eslint-disable-next-line no-use-before-define
						error.call(that);

						return;
					}

					if (resp.statusCode >= 400) {
						// eslint-disable-next-line no-use-before-define
						error.call(that);

						return;
					}

					success();
				});
			},
		});

		var error = function () {
			/* eslint-disable no-invalid-this */
			// eslint-disable-next-line max-len
			var messageContainer = this.el.querySelector('[data-hook~="form-message"]');

			visitor.event({
				eventCategory: 'Form',
				eventAction: 'Submission',
				eventLabel: 'Error',
			});

			dom.addClass(contactForm.el, 'has-error');

			if (messageContainer) {
				messageContainer.innerHTML = [
					'Something’s not right. ',
					'Please check your details and try again',
				].join('');
			}
			this.enableForm();
			/* eslint-enable no-invalid-this */
		};

		contactFormContainer.registerSubview(contactForm);

		var formQuery = contactForm.el.querySelector.bind(contactForm.el);
		var form = {
			name: formQuery('[data-hook~="name"]'),
			contactMethod: formQuery('[data-hook~="contact-method"]'),
			emailTel: formQuery('[data-hook~="email-tel"]'),
			captcha: formQuery('[data-hook~="captcha"]'),
			message: formQuery('[data-hook~="message"]'),
		};

		var initialFields = {
			name: new ContactInputView({
				template: templates.includes.formInput,
				name: 'name',
				label: 'My full name is',
				placeholder: 'Steve Jobs',
				containerEl: form.name,
				validClass: 'has-success',
				invalidClass: 'has-error',
				validityClassSelector: '.form__group',
			}),
			contactMethod: new ContactRadioGroup({
				template: templates.includes.formRadioGroup,
				radioTemplate: templates.includes.formRadioInput,
				name: 'contactMethod',
				label: 'I’d like you to',
				options: [{
					value: 'tel',
					label: 'Call me',
				}, {
					value: 'email',
					label: 'Email me',
				}],
				containerEl: form.contactMethod,
				validClass: 'has-success',
				invalidClass: 'has-error',
				validityClassSelector: '.form__radioGroup',
			}),
			captcha: new ContactCaptchaView({
				template: templates.includes.formInput,
				name: 'captcha',
				label: 'I am a human being',
				containerEl: form.captcha,
				validClass: 'has-success',
				invalidClass: 'has-error',
				validityClassSelector: '.form__group',
			}),
			message: new ContactInputView({
				template: templates.includes.formInput,
				name: 'message',
				type: 'textarea',
				label: 'I want to talk about',
				placeholder: 'Type your message here…',
				required: false,
				containerEl: form.message,
				validClass: 'has-success',
				invalidClass: 'has-error',
				validityClassSelector: '.form__group',
			}),
		};

		for (var field in initialFields) {
			if (Object.prototype.hasOwnProperty.call(initialFields, field)) {
				contactForm.fieldContainerEl =
					initialFields[field].containerEl;
				contactForm.addField(initialFields[field]);
			}
		}

		this.listenToAndRun(initialFields.contactMethod, 'change:value',
			function (contactMethod) {
				contactMethod = contactMethod || initialFields.contactMethod;
				var oldMethod = contactMethod.previousAttributes().value;

				contactForm.removeField(oldMethod);

				if (contactMethod.value) {
					var methodField;

					switch (contactMethod.value) {
						case 'tel':
							methodField = new ContactInputView({
								template: templates.includes.formInput,
								name: 'tel',
								type: 'tel',
								label: 'My number is',
								placeholder: '082 123 0000',
								containerEl: form.emailTel,
								validClass: 'has-success',
								invalidClass: 'has-error',
								validityClassSelector: '.form__group',
							});
							break;
						case 'email':
							methodField = new ContactInputView({
								template: templates.includes.formInput,
								name: 'email',
								type: 'email',
								label: 'My email is',
								placeholder: 'stevej@place.com',
								containerEl: form.emailTel,
								validClass: 'has-success',
								invalidClass: 'has-error',
								validityClassSelector: '.form__group',
							});
							break;
					}
					contactForm.fieldContainerEl = methodField.containerEl;
					contactForm.addField(methodField);
				}
			});

		if (location.hash.replace(/^#/, '') === 'success') {
			success();
		}
	},
});
