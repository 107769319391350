var assign = require('lodash.assign');
var app = require('ampersand-app');
var dom = require('ampersand-dom');
var gsap = require('gsap');
var transitions = require('../helpers/transitions');
var FormView = require('ampersand-form-view');
var InputView = require('ampersand-input-view');
var PageView = require('../views/base');
var templates = require('../templates');


var SearchForm = FormView.extend({
	autoAppend: false,
	disableForm: function () {
		Array.prototype.forEach.call(
			this.el.querySelectorAll('input, button, textarea, select'),
			function (elem) {
				elem.dataset.originallyDisabled = String(elem.disabled);
				elem.disabled = true;
			}
		);
	},
	enableForm: function () {
		Array.prototype.forEach.call(
			this.el.querySelectorAll('input, button, textarea, select'),
			function (elem) {
				switch (elem.dataset.originallyDisabled) {
					case 'true':
						elem.disabled = true;
						break;
					case 'false':
						elem.disabled = false;
						break;
					default:
						throw new Error("Invalid value '" +
							elem.dataset.originallyDisabled + "'");
				}
				delete elem.dataset.originallyDisabled;
			}
		);
	},
});

var SearchInputView = InputView.extend({
	/* eslint-disable quote-props */
	bindings: assign({}, {
		'required': {
			type: 'booleanClass',
			name: 'is-required',
		},
	}, InputView.prototype.bindings),
	/* eslint-enable quote-props */
	props: {
		containerEl: 'object',
	},
});

module.exports = PageView.extend({

	pageTitle: '404: Page Not Found',

	template: templates.pages.notFound,

	menu: {
		logo: false,
		hint: false,
	},

	initialize: function () {
		this.once('remove', this.cleanup, this);
		this.replacePageClass();
	},

	cleanup: function () {
		window.removeEventListener('optimizedResize',
			this.boundWindowSizeRespond);
		window.removeEventListener('orientationchange',
			this.boundWindowSizeRespond);
	},

	render: function () {
		this.renderWithTemplate(this);

		this.backgroundVideo = this.queryByHook('background-video');

		this.boundWindowSizeRespond = this.windowResizeRespond.bind(this);
		window.addEventListener('optimizedResize',
			this.boundWindowSizeRespond);
		window.addEventListener('orientationchange',
			this.boundWindowSizeRespond);
		this.windowResizeRespond();

		this.renderForm();

		return this;
	},

	renderForm: function () {
		var searchForm = new SearchForm({
			el: this.queryByHook('form-container'),
			validCallback: function (valid) {
				switch (valid) {
					case true:
						dom.removeClass(this.el, 'has-error');
						break;
					case false:
						dom.addClass(this.el, 'has-error');
						break;
				}
			},
			submitCallback: function (data) {
				location.href = 'https://www.google.com/?q=site:' +
					location.host + '+' + encodeURIComponent(data.search);
			},
		});

		this.registerSubview(searchForm);

		var formQuery = searchForm.el.querySelector.bind(searchForm.el);
		var form = {
			search: formQuery('[data-hook~="search"]'),
		};

		var searchTerm = decodeURIComponent(location.pathname);

		searchTerm = searchTerm.replace(/\//g, ' ').trim();
		searchTerm = searchTerm.charAt(0).toUpperCase() + searchTerm.slice(1);

		var initialFields = {
			search: new SearchInputView({
				template: templates.includes.formInput,
				name: 'search',
				label: 'Were you looking for:',
				placeholder: 'Something else',
				value: searchTerm,
				containerEl: form.search,
				validClass: 'has-success',
				invalidClass: 'has-error',
				validityClassSelector: '.form__group',
			}),
		};

		for (var field in initialFields) {
			if (Object.prototype.hasOwnProperty.call(initialFields, field)) {
				searchForm.fieldContainerEl =
					initialFields[field].containerEl;
				searchForm.addField(initialFields[field]);
			}
		}
	},

	windowResizeRespond: function () {
		switch (app.findSize()) {
			case 'mobile':
			case 'phablet':
				this.playVideo(false);
				break;
			default:
				this.playVideo(true);
		}
	},

	playVideo: function (play) {
		if (typeof play !== 'boolean') {
			throw new TypeError("'play' should be boolean!");
		}
		if (this.backgroundVideo instanceof HTMLVideoElement) {
			switch (play) {
				case true:
					this.backgroundVideo.play();
					gsap.TweenMax.to(this.backgroundVideo,
						transitions.DURATION,
						{
							playbackRate: 1,
							ease: transitions.EASEOUT,
						});
					break;
				case false:
					gsap.TweenMax.to(this.backgroundVideo,
						transitions.DURATION,
						{
							playbackRate: 0,
							ease: transitions.EASEIN,
							onComplete: this.backgroundVideo.pause.bind(
								this.backgroundVideo),
						});
					break;
			}
		}
	},

	replacePageClass: function () {
		var body = document.body;
		var classesToRemove = [];

		Array.prototype.forEach.call(body.classList, function (className) {
			if (/^page-/.test(className)) {
				classesToRemove.push(className);
			}
		});
		classesToRemove.forEach(function (className) {
			body.classList.remove(className);
		});
		body.classList.add('page-notfound');
	},
});
