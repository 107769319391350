/* globals define */
(function (root, factory) {

	if (root === null) {
		throw new Error('Recaptcha package can be used only in browser');
	}

	if (typeof define === 'function' && define.amd) {
		define(factory);
	} else if (typeof exports === 'object') {
		module.exports = factory();
	} else {
		root.RecaptchaLoader = factory();
	}

})(typeof window === 'undefined' ? null : window, function () {

	var script = null;

	var recaptcha = null;

	var loading = false;

	var callbacks = [];

	var onLoadEvents = [];

	var originalCreateLoaderMethod = null;

	var RecaptchaLoader = {};

	RecaptchaLoader.URL =
		'https://www.google.com/recaptcha/api.js';

	RecaptchaLoader.RENDER = 'explicit';

	RecaptchaLoader.WINDOW_CALLBACK_NAME = '__recaptcha_initializator__';

	RecaptchaLoader.recaptchaMockApiObject = {};

	RecaptchaLoader.load = function (fn) {
		if (recaptcha === null) {
			if (loading === true) {
				if (fn) {
					callbacks.push(fn);
				}
			} else {
				loading = true;

				window[RecaptchaLoader.WINDOW_CALLBACK_NAME] = function () {
					// eslint-disable-next-line no-use-before-define
					ready(fn);
				};

				RecaptchaLoader.createLoader();
			}
		} else if (fn) {
			fn(recaptcha);
		}
	};

	RecaptchaLoader.createLoader = function () {
		script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = RecaptchaLoader.createUrl();

		document.body.appendChild(script);
	};

	RecaptchaLoader.isLoaded = function () {
		return recaptcha !== null;
	};

	RecaptchaLoader.createUrl = function () {
		var url = RecaptchaLoader.URL;

		url += '?onload=' + RecaptchaLoader.WINDOW_CALLBACK_NAME;

		url += '&render=' + RecaptchaLoader.RENDER;

		return url;
	};

	RecaptchaLoader.release = function (fn) {
		var release = function () {

			recaptcha = null;
			loading = false;
			callbacks = [];
			onLoadEvents = [];

			if (typeof window.recaptcha !== 'undefined') {
				delete window.recaptcha;
			}

			if (typeof window[RecaptchaLoader.WINDOW_CALLBACK_NAME] !==
					'undefined') {
				delete window[RecaptchaLoader.WINDOW_CALLBACK_NAME];
			}

			if (originalCreateLoaderMethod !== null) {
				RecaptchaLoader.createLoader = originalCreateLoaderMethod;
				originalCreateLoaderMethod = null;
			}

			if (script !== null) {
				script.parentElement.removeChild(script);
				script = null;
			}

			if (fn) {
				fn();
			}
		};

		if (loading) {
			RecaptchaLoader.load(function () {
				release();
			});
		} else {
			release();
		}
	};

	RecaptchaLoader.onLoad = function (fn) {
		onLoadEvents.push(fn);
	};

	RecaptchaLoader.makeMock = function () {
		originalCreateLoaderMethod = RecaptchaLoader.createLoader;

		RecaptchaLoader.createLoader = function () {
			window.recaptcha = RecaptchaLoader.recaptchaMockApiObject;
			window[RecaptchaLoader.WINDOW_CALLBACK_NAME]();
		};
	};

	var ready = function (fn) {
		var i;

		loading = false;

		if (recaptcha === null) {
			recaptcha = window.grecaptcha;
		}

		for (i = 0; i < onLoadEvents.length; i++) {
			onLoadEvents[i](recaptcha);
		}

		if (fn) {
			fn(recaptcha);
		}

		for (i = 0; i < callbacks.length; i++) {
			callbacks[i](recaptcha);
		}

		callbacks = [];
	};

	return RecaptchaLoader;

});
