var debounce = require('lodash.debounce');
var Hammer = require('hammerjs');
var app = require('ampersand-app');
var PageView = require('../views/base');
var preloader = require('../helpers/preloader');
var templates = require('../templates');


module.exports = PageView.extend({

	pageTitle: 'Home',

	template: templates.pages.home,

	assets: {
		fonts: preloader.common.fonts,
		images: preloader.common.images,
	},

	initialize: function () {
		this.once('remove', this.cleanup, this);
	},

	cleanup: function () {
		this.unbindEvents();
	},

	menu: {
		hint: false,
		toggle: false,
	},

	render: function () {
		this.renderWithTemplate(this);
		this.bindEvents();

		return this;
	},

	bindEvents: function () {
		var that = this;

		// Define touch events
		this.mc = new Hammer.Manager(this.el, {
			touchAction: 'auto',
			recognizers: [
				[Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }],
			],
		});
		this.mc.on('swipeleft', function (e) {
			if (e.pointerType === 'touch') {
				that.navRight();
			}
		});
		this.mc.on('swiperight', function (e) {
			if (e.pointerType === 'touch') {
				that.navLeft();
			}
		});

		// Listen to scroll events
		this.boundScroll = this.scroll.bind(this);
		this.el.addEventListener('wheel', this.boundScroll);

		// Listen to keyboard events
		this.boundKeyPress = this.keyPress.bind(this);
		window.addEventListener('keydown', this.boundKeyPress);
	},

	unbindEvents: function () {
		this.mc.destroy();
		this.el.removeEventListener('wheel', this.boundScroll);
		window.removeEventListener('keydown', this.boundKeyPress);
	},

	scroll: debounce(function (e) {
		/* eslint-disable no-invalid-this */
		if (e.deltaX > 0) {
			this.navRight();
		}
		if (e.deltaX < 0) {
			this.navLeft();
		}
		/* eslint-enable no-invalid-this */
	}, 300, true),

	keyPress: function (e) {
		if (e.defaultPrevented) {
			return;
		}

		if (e.target instanceof HTMLInputElement ||
				e.target instanceof HTMLTextAreaElement) {
			return;
		}

		switch (e.key) {
			case 'ArrowLeft':
				this.navLeft();
				break;
			case 'ArrowRight':
				this.navRight();
				break;
			default:
				return;
		}

		e.preventDefault();
	},

	navLeft: function () {
		app.navigate('/vision');
	},

	navRight: function () {
		app.navigate('/workshop');
	},
});
