/* eslint-disable */
(function (ElementProto) {
	if (typeof ElementProto.matches !== 'function') {
		ElementProto.matches = ElementProto.msMatchesSelector || ElementProto.mozMatchesSelector || ElementProto.webkitMatchesSelector || function matches(selector) {
			var element = this;
			var elements = (element.document || element.ownerDocument).querySelectorAll(selector);
			var index = 0;

			while (elements[index] && elements[index] !== element) {
				++index;
			}

			return Boolean(elements[index]);
		};
	}

	if (typeof ElementProto.closest !== 'function') {
		ElementProto.closest = function closest(selector) {
			var element = this;

			while (element && element.nodeType === 1) {
				if (element.matches(selector)) {
					return element;
				}

				element = element.parentNode;
			}

			return null;
		};
	}
})(window.Element.prototype);
/* eslint-enable */

var app = require('ampersand-app');
var xhr = require('xhr');
var gsap = require('gsap');
var transitions = require('../../helpers/transitions');
var visitor = require('../../helpers/visitor');
var dom = require('ampersand-dom');
var SectionNav = require('../../views/section-nav');
var PageView = require('../../views/base');

module.exports = PageView.extend({

	initialize: function () {
		this.once('remove', this.cleanup, this);
	},

	cleanup: function () {
		window.removeEventListener('optimizedResize',
			this.boundWindowSizeRespond);
		window.removeEventListener('orientationchange',
			this.boundWindowSizeRespond);
	},

	render: function () {
		// main renderer
		this.renderWithTemplate(this);

		this.backgroundVideo = this.queryByHook('background-video');
		this.backgroundBlur = this.queryByHook('background-blur');
		this.sectionContainer = this.queryByHook('section-container');

		if (this.sectionContainer) {
			this.initSectionViewer();
			if (this.gallery) {
				this.initGalleryListener();
			}
		}

		this.initFigureFrames();

		return this;
	},

	initGalleryListener: function () {
		var galleryItems = this.sectionContainer.querySelectorAll('img, video');

		Array.prototype.forEach.call(galleryItems, function (el) {
			el.tabIndex = 0;
			el.addEventListener('click', function (e) {
				app.trigger('galleryStart', {
					items: galleryItems,
					e: e,
				});
			});
			el.addEventListener('keydown', function (e) {
				if (e.key === 'Enter') {
					app.trigger('galleryStart', {
						items: galleryItems,
						e: e,
					});
					e.stopPropagation();
				}
			});

			if (el instanceof HTMLVideoElement) {
				el.closest('figure').addEventListener('click', function (e) {
					app.trigger('galleryStart', {
						items: galleryItems,
						e: e,
					});
				});
			}
		});
	},

	windowResizeRespond: function () {
		if (this.sectionIndex === 0) {
			switch (app.findSize()) {
				case 'mobile':
				case 'phablet':
					this.playVideo(false);
					break;
				default:
					this.playVideo(true);
			}
		}
	},

	initSectionViewer: function () {
		var that = this;

		this.on('view:change', function (index) {
			that.sectionIndex = index;
			switch (index) {
				case 0:
					switch (app.findSize()) {
						case 'mobile':
						case 'phablet':
							// Do nothing
							break;
						default:
							that.playVideo(true);
					}
					that.blurBackground(false);
					break;
				default:
					that.playVideo(false);
					that.blurBackground(true);
			}
			visitor.event({
				eventCategory: 'View',
				eventAction: 'Switch',
				eventLabel: 'Index: ' + index,
			});
		});
		this.boundWindowSizeRespond = this.windowResizeRespond.bind(this);
		window.addEventListener('optimizedResize',
			this.boundWindowSizeRespond);
		window.addEventListener('orientationchange',
			this.boundWindowSizeRespond);

		var sectionNavOptions = {};

		if (this.sectionSelectors) {
			sectionNavOptions.selectors = this.sectionSelectors;
		}

		this.sectionSwitcher = new SectionNav(sectionNavOptions);
		this.renderSubview(this.sectionSwitcher);
	},

	blurBackground: function (blur) {
		if (typeof blur !== 'boolean') {
			throw new TypeError("'blur' should be boolean!");
		}
		if (this.backgroundBlur instanceof HTMLElement) {
			switch (blur) {
				case true:
					dom.addClass(this.backgroundBlur, 'is-blurred');
					break;
				case false:
					dom.removeClass(this.backgroundBlur, 'is-blurred');
					break;
			}
		}
	},

	playVideo: function (play) {
		if (typeof play !== 'boolean') {
			throw new TypeError("'play' should be boolean!");
		}
		if (this.backgroundVideo instanceof HTMLVideoElement) {
			switch (play) {
				case true:
					this.backgroundVideo.play();
					gsap.TweenMax.to(this.backgroundVideo,
						transitions.DURATION,
						{
							playbackRate: 1,
							ease: transitions.EASEOUT,
						});
					break;
				case false:
					gsap.TweenMax.to(this.backgroundVideo,
						transitions.DURATION,
						{
							playbackRate: 0,
							ease: transitions.EASEIN,
							onComplete: this.backgroundVideo.pause.bind(
								this.backgroundVideo),
						});
					break;
			}
		}
	},

	initFigureFrames: function () {
		this.queryAll('[data-hook~="figure-frame"]')
			.forEach(function (figureFrame) {
				var frames = Array.prototype.slice.call(
					figureFrame.querySelectorAll('[data-frame]'));
				var current = 0;

				dom.addClass(frames[current], 'is-active');
				setInterval(function () {
					if (current < frames.length - 1) {
						current += 1;
					} else {
						current = 0;
					}
					dom.addClass(frames[current], 'is-active');
					frames.filter(function (frame) {
						return frame !== frames[current];
					}).forEach(function (frame) {
						dom.removeClass(frame, 'is-active');
					});
				}, 2000);
			});

		this.queryAll('figure video').forEach(function (video) {
			// Add the play button
			xhr({
				uri: '/images/play.svg',
				responseType: 'document',
			}, function (err, resp) {
				if (err) return;
				if (resp.statusCode >= 400) return;

				var svg = document.importNode(resp.body.documentElement, true);

				svg.classList.add('workshop__figurePlay');

				video.closest('figure').appendChild(svg);
			});
		});
	},
});
