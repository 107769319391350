var WorkshopSubview = require('./workshop-subview');
var templates = require('../../templates');


module.exports = WorkshopSubview.extend({
	pageTitle: 'Origins',
	template: templates.pages.workshop.origins,
	gallery: true,
	menu: {
		contact: true,
	},
});
