// This app view is responsible for rendering all content that goes into
// <html>. It's initted right away and renders itself on DOM ready.
var app = require('ampersand-app');
var View = require('./base');
var dom = require('ampersand-dom');
var platform = require('platform');
var ViewSwitcher = require('../helpers/view-switcher');
var result = require('lodash.result');
var domify = require('domify');
var localLinks = require('local-links');
var preloader = require('../helpers/preloader');
var transitions = require('../helpers/transitions');
var visitor = require('../helpers/visitor');
var MenuView = require('./menu');
var LoaderView = require('./loader');
var GalleryView = require('./gallery');
var templates = require('../templates');
var Perlin = require('../helpers/perlin');
var VisionPage = require('../pages/vision');
var WorkshopPage = require('../pages/workshop');


module.exports = View.extend({
	template: templates.body,
	autoRender: true,
	initialize: function () {
		var that = this;

		// this marks the correct nav item selected
		this.listenTo(app, 'page', this.handleNewPage);

		this.listenTo(app, 'galleryStart', function (data) {
			that.galleryViewSwitcher.set(new GalleryView(data));
		});
		this.listenTo(app, 'galleryClose', function () {
			that.galleryViewSwitcher.clear();
		});

		app.updateActiveNav = this.updateActiveNav.bind(this);
	},
	events: {
		'click a[href]': 'handleLinkClick',
		'focus a[href]': 'setOutline',
		'blur a[href]': 'resetOutline',
		'focus [tabindex]': 'setOutline',
		'blur [tabindex]': 'resetOutline',
	},
	render: function () {
		var that = this;

		// some additional stuff we want to add to the document head
		document.head.appendChild(domify(templates.head()));

		// main renderer
		this.renderWithTemplate(this);

		this.animateGrain();

		// init and configure our page switcher
		this.viewSwitcher = new ViewSwitcher(
			this.queryByHook('page-container'), {
				parent: this,
				show: function (newView) {
					// it's inserted and rendered for me
					document.title = result(newView, 'pageTitle') +
						' — Envisioneers' ||
						'Envisioneers';
					document.scrollTop = 0;

					var oldView = app.state.mainView;

					if (oldView) {
						if (newView instanceof VisionPage &&
								oldView instanceof WorkshopPage) {
							transitions.fadeInFromLeft(newView.el);
						} else if (newView instanceof WorkshopPage &&
								oldView instanceof VisionPage) {
							transitions.fadeInFromRight(newView.el);
						} else {
							transitions.fadeIn(newView.el);
						}
					}

					if (!newView.viewSwitcher) {
						that.setMenu(newView.menu);
					}

					// store an additional reference, just because
					app.state.mainView = newView;
				},
				hide: function (oldView, newView, callback) {
					if (oldView instanceof VisionPage &&
							newView instanceof WorkshopPage) {
						transitions.fadeOutToLeft(oldView.el, callback);
					} else if (oldView instanceof WorkshopPage &&
							newView instanceof VisionPage) {
						transitions.fadeOutToRight(oldView.el, callback);
					} else {
						transitions.fadeOut(oldView.el, callback);
					}
				},
			}
		);

		// Render menu
		this.renderSubview(new MenuView({ model: app.state.menu }),
			this.queryByHook('menu-container'));

		// Init gallery viewSwitcher
		this.galleryViewSwitcher = new ViewSwitcher(
			this.queryByHook('gallery-container'), {
				parent: this,
				show: function (galleryView) {
					transitions.fadeIn(galleryView.el);
					if (galleryView.menu) {
						that.setMenu(galleryView.menu);
					}
				},
				hide: function (galleryView, callback) {
					transitions.fadeOut(galleryView.el, callback);
				},
			}
		);

		// Init loader viewSwitcher
		this.loaderViewSwitcher = new ViewSwitcher(
			this.queryByHook('loader-container'), {
				parent: this,
				show: function (loader) {
					transitions.fadeIn(loader.el);
				},
				hide: function (loader, callback) {
					loader.close(function () {
						transitions.fadeOut(loader.el, callback);
					});
				},
			}
		);

		dom.addClass(document.documentElement,
			platform.name === 'Safari' ? 'safari' : 'no-safari');

		return this;
	},

	handleNewPage: function (view) {
		var that = this;

		visitor.pageview({
			title: view.pageTitle,
		});

		// tell the view switcher to render the new one
		if (view.assets) {
			var promise = preloader(view.assets).then(function () {
				that.loaderViewSwitcher.clear();
				that.viewSwitcher.set(view);
				that.updateActiveNav();
			});

			setTimeout(function () {
				if (promise.isPending()) {
					that.viewSwitcher.clear();
					that.loaderViewSwitcher.set(new LoaderView());
				}
			}, 300);
		} else {
			this.viewSwitcher.set(view);
			this.updateActiveNav();
		}
	},

	// Handles all `<a>` clicks in the app not handled
	// by another view. This lets us determine if this is
	// a click that should be handled internally by the app.
	handleLinkClick: function (e) {
		if (/^(mailto|tel):/.test(e.delegateTarget.href)) return;
		// This module determines whether a click event is
		// a local click (making sure the for modifier keys, etc)
		// and dealing with browser quirks to determine if this
		// event was from clicking an internal link. That we should
		// treat like local navigation.
		var localPath = localLinks.pathname(e);

		if (localPath) {
			e.preventDefault();
			app.navigate(localPath);
		}
	},

	listenForKeyUp: function (el, e) {
		if (e.key === 'Tab') {
			dom.addClass(el, 'has-outline');
		}
	},

	setOutline: function (e) {
		this.boundListenForKeyUp =
			this.listenForKeyUp.bind(this, e.delegateTarget);
		window.addEventListener('keyup', this.boundListenForKeyUp);
	},

	resetOutline: function (e) {
		dom.removeClass(e.delegateTarget, 'has-outline');
		window.removeEventListener('keyup', this.boundListenForKeyUp);
	},

	updateActiveNav: function () {
		var path = window.location.pathname.slice(1);

		app.state.path = path;

		this.queryAll('nav a[href]').forEach(function (aTag) {
			if (/^(mailto|tel):/.test(aTag.pathname)) return;
			var aPath = aTag.pathname.slice(1);

			if (!aPath && !path || aPath && path.indexOf(aPath) === 0) {
				dom.addClass(aTag.parentNode, 'is-active');
			} else {
				dom.removeClass(aTag.parentNode, 'is-active');
			}
		});
	},

	animateGrain: function () {
		var that = this;

		var depth = 1.5;
		var speed = 3;

		var crisp = this.queryByHook('grain-crisp');
		var blurred = this.queryByHook('grain-blurred');

		var counter = 0;

		var mapRange = function (value, low1, high1, low2, high2) {
			return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
		};

		var perlin = new Perlin();

		var animateBackground = function () {

			if (!that.grainMoving) return;

			// Get window size (not cached, because screen size may change)
			var windowSize = {
				x: window.innerWidth,
				y: window.innerHeight,
			};

			var target = {
				x: mapRange(perlin.get1d(counter), 0, 1,
					-windowSize.x / 2, windowSize.x / 2),
				y: mapRange(perlin.get1d(counter + 10000, 0), 0, 1,
					-windowSize.y / 2, windowSize.y / 2),
			};

			crisp.style.transform =
				'translate3d(' + target.x + 'px, ' + target.y + 'px, 0)';
			blurred.style.transform = 'scale(' + depth + ') ' +
				'translate3d(' + target.x + 'px, ' + target.y + 'px, 0)';

			counter += speed * 0.0001;

			requestAnimationFrame(animateBackground);
		};

		var playOrPause = function () {
			switch (app.findSize()) {
				case 'mobile':
				case 'phablet':
					that.grainMoving = false;
					crisp.style.transform = null;
					blurred.style.transform = 'scale(' + depth + ')';
					break;
				default:
					if (!that.grainMoving) {
						that.grainMoving = true;
						requestAnimationFrame(animateBackground);
					}
			}
		};

		playOrPause();

		// Not removing these listeners because this view will never be
		// removed.
		window.addEventListener('optimizedResize', playOrPause);
		window.addEventListener('orientationchange', playOrPause);
	},
});
