var assign = require('lodash.assign');
var gsap = require('gsap');

var options = {
	EASEIN: gsap.Sine.easeIn,
	EASEOUT: gsap.Sine.easeOut,
	DURATION: 0.2,
};

var transitionToFactory = function (props, ease, duration) {
	ease = ease || options.EASEOUT;
	duration = duration || options.DURATION;

	return function (el, callback) {
		var onComplete = function () {
			if (callback) callback();
		};

		gsap.TweenMax.to(el, duration, assign({}, props, {
			ease: ease,
			onComplete: onComplete,
		}));
	};
};

var transitionFromFactory = function (props, ease, duration) {
	ease = ease || options.EASEIN;
	duration = duration || options.DURATION;

	return function (el, callback) {
		var onComplete = function () {
			if (callback) callback();
		};

		gsap.TweenMax.from(el, duration, assign({}, props, {
			ease: ease,
			onComplete: onComplete,
		}));
	};
};

// eslint-disable-next-line no-unused-vars
var transitionFromToFactory = function (fromProps, toProps, ease, duration) {
	ease = ease || options.EASEIN;
	duration = duration || options.DURATION;

	return function (el, callback) {
		var onComplete = function () {
			if (callback) callback();
		};

		gsap.TweenMax.fromTo(el, duration, fromProps, assign({}, toProps, {
			ease: ease,
			onComplete: onComplete,
		}));
	};
};


module.exports = assign(options, {

	fadeIn: transitionFromFactory({ opacity: 0 }),

	fadeOut: transitionToFactory({ opacity: 0 }),

	fadeInFromLeft: transitionFromFactory({
		opacity: 0,
		left: '-=5%',
	}),

	fadeInFromRight: transitionFromFactory({
		opacity: 0,
		left: '+=5%',
	}),

	fadeInFromTop: transitionFromFactory({
		opacity: 0,
		top: '-=5%',
	}),

	fadeInFromBottom: transitionFromFactory({
		opacity: 0,
		top: '+=5%',
	}),

	fadeOutToLeft: transitionToFactory({
		opacity: 0,
		left: '-=5%',
	}),

	fadeOutToRight: transitionToFactory({
		opacity: 0,
		left: '+=5%',
	}),

	fadeOutToTop: transitionToFactory({
		opacity: 0,
		top: '-=5%',
	}),

	fadeOutToBottom: transitionToFactory({
		opacity: 0,
		top: '+=5%',
	}),

	absoluteFadeIn: transitionFromToFactory({ opacity: 0 }, { opacity: 1 }),

	absoluteFadeInFromLeft: transitionFromToFactory({
		opacity: 0,
		left: '-5%',
	}, {
		opacity: 1,
		left: '0',
	}),

	absoluteFadeInFromRight: transitionFromToFactory({
		opacity: 0,
		left: '5%',
	}, {
		opacity: 1,
		left: '0',
	}),

	absoluteFadeInFromTop: transitionFromToFactory({
		opacity: 0,
		top: '-5%',
	}, {
		opacity: 1,
		top: '0',
	}),

	absoluteFadeInFromBottom: transitionFromToFactory({
		opacity: 0,
		top: '5%',
	}, {
		opacity: 1,
		top: '0',
	}),

});
