var View = require('ampersand-view');
var dom = require('ampersand-dom');
var matchesSelector = require('matches-selector');

var slice = Array.prototype.slice;

var getMatches = function (el, selector) {
	if (selector === '') return [el];
	var matches = [];

	if (matchesSelector(el, selector)) matches.push(el);

	return matches.concat(slice.call(el.querySelectorAll(selector)));
};

/* eslint-disable indent */
var defaultTemplate = [
	'<div>',
		'<span data-hook="label"></span>',
		'<div data-hook="radio-container"></div>',
		'<div data-hook="message-container">',
			'<p data-hook="message-text"></p>',
		'</div>',
	'</div>',
].join('');
var defaultRadioTemplate = [
	'<label>',
		'<input type="radio">',
		'<span data-hook="label"></span>',
	'</label>',
].join('');
/* eslint-enable indent */


var RadioView = View.extend({
	bindings: {
		/* eslint-disable quote-props */
		'name': {
			type: 'attribute',
			selector: 'input[type="radio"]',
			name: 'name',
		},
		'value': {
			type: 'attribute',
			selector: 'input[type="radio"]',
			name: 'value',
		},
		'id': [
			{
				type: 'attribute',
				selector: 'input[type="radio"]',
				name: 'id',
			},
			{
				type: 'attribute',
				selector: 'label',
				name: 'for',
			},
		],
		'label': [
			{
				hook: 'label',
			},
			{
				type: 'toggle',
				hook: 'label',
			},
		],
		/* eslint-enable quote-props */
	},
	initialize: function () {
		this.handleChange = this.handleChange.bind(this);
	},
	render: function () {
		this.renderWithTemplate();
		this.input = this.query('input[type="radio"]');
		this.initInputBindings();
		this.setChecked(this.checked);

		return this;
	},
	props: {
		value: ['any', true],
		name: ['string', true],
		checked: ['boolean', true, false],
		label: ['string', true, ''],
	},
	derived: {
		id: {
			deps: ['name', 'value'],
			fn: function () {
				return this.name + '-' + this.value;
			},
		},
	},
	handleChange: function () {
		this.checked = this.input.checked;
		this.parent.update(this);
	},
	setChecked: function (checked) {
		this.checked = checked;
		this.input.checked = checked;
	},
	beforeSubmit: function () {
		// catch undetected input changes that were not caught due to lack of
		// browser event firing see:
		// https://github.com/AmpersandJS/ampersand-input-view/issues/2
		this.checked = this.input.checked;
	},
	initInputBindings: function () {
		this.input.addEventListener('change', this.handleChange, false);
	},
	remove: function () {
		this.input.removeEventListener('change', this.handleChange, false);
		View.prototype.remove.apply(this, arguments);
	},
});


module.exports = View.extend({
	initialize: function () {
		if (!this.label) this.label = this.name;
		this.startingValue = this.value;
		this.radios = [];
		this.on('change:valid change:value', this.updateParent, this);
		this.on('change:validityClass', this.validityClassChanged, this);
	},
	render: function () {
		var that = this;

		if (this.rendered) return;
		this.renderWithTemplate();
		this.rendered = true;
		this.options.forEach(function (option) {
			that.addRadio(option);
		});
		if (this.value) this.setValue(this.value);

		return this;
	},
	bindings: {
		/* eslint-disable quote-props */
		'label': {
			hook: 'label',
		},
		'message': {
			type: 'text',
			hook: 'message-text',
		},
		'showMessage': {
			type: 'toggle',
			hook: 'message-container',
		},
		/* eslint-enable quote-props */
	},
	props: {
		name: ['string', true, ''],
		value: {
			type: 'any',
			required: true,
			'default': null,
			allowNull: true,
		},
		startingValue: 'any',
		options: ['array', true, function () {
			return [];
		}],
		label: ['string', true, ''],
		required: ['boolean', true, true],
		message: ['string', true, ''],
		requiredMessage: ['string', true, 'This field is required'],
		validClass: ['string', true, 'input-valid'],
		invalidClass: ['string', true, 'input-invalid'],
		template: ['any', true, defaultTemplate],
		radioTemplate: ['any', true, defaultRadioTemplate],
		validityClassSelector: ['string', true, ''],
	},
	session: {
		shouldValidate: ['boolean', true, false],
		rendered: ['boolean', true, false],
	},
	derived: {
		valid: {
			deps: ['required', 'value'],
			fn: function () {
				return this.required &&
					(typeof this.value !== 'undefined' && this.value !== null);
			},
		},
		changed: {
			deps: ['value', 'startingValue'],
			fn: function () {
				return this.value !== this.startingValue;
			},
		},
		showMessage: {
			deps: ['message', 'shouldValidate'],
			fn: function () {
				return this.shouldValidate && this.message;
			},
		},
		validityClass: {
			deps: ['valid', 'validClass', 'invalidClass', 'shouldValidate'],
			fn: function () {
				if (this.shouldValidate) {
					return this.valid ? this.validClass : this.invalidClass;
				}

				return '';
			},
		},
	},
	setValue: function (value) {
		if (typeof value === 'undefined') {
			throw new Error('Value should not be undefined');
		}
		this.radios.forEach(function (radio) {
			if (value === radio.value) {
				radio.setChecked(true);
			} else {
				radio.setChecked(false);
			}
		});
		this.set({ value: value });
	},
	update: function (changedRadio) {
		this.radios.forEach(function (radio) {
			if (changedRadio === radio) {
				radio.setChecked(true);
			} else {
				radio.setChecked(false);
			}
		});
		var value = this.radios.reduce(function (previous, radio) {
			if (radio.checked) {
				return radio.value;
			}

			return previous;
		}, undefined);

		this.set({ value: value });
	},
	beforeSubmit: function () {
		this.radios.forEach(function (radio) {
			radio.beforeSubmit();
		});
		this.shouldValidate = true;
		if (!this.valid) {
			this.message = this.requiredMessage;
		}
	},
	reset: function () {
		this.setValue(this.startingValue);
	},
	clear: function () {
		this.setValue(null, true);
	},
	addRadio: function (value) {
		var initOptions = {
			value: value.value,
			label: value.label,
			parent: this,
			name: this.name,
			template: this.radioTemplate,
		};
		var radio = new RadioView(initOptions);

		radio.render();
		this.radios.push(radio);
		this.queryByHook('radio-container').appendChild(radio.el);

		return radio;
	},
	updateParent: function () {
		if (this.parent) this.parent.update(this);
	},
	validityClassChanged: function (view, newClass) {
		var oldClass = view.previousAttributes().validityClass;

		getMatches(this.el, this.validityClassSelector)
			.forEach(function (match) {
				dom.switchClass(match, oldClass, newClass);
			});
	},
});
