/* eslint-disable no-underscore-dangle, func-style, no-unused-expressions */
/* eslint-disable no-prototype-builtins */
function ViewSwitcher(el, options) {
	options || (options = {});
	this.el = el;
	this.config = {
		hide: null,
		show: null,
		empty: null,
		parent: null,
		prepend: false,
		waitForRemove: false,
	};
	for (var item in options) {
		if (this.config.hasOwnProperty(item)) {
			this.config[item] = options[item];
		}
	}
	if (options.view) {
		this._setCurrent(options.view);
		this._render(options.view);
	} else {
		// call this so the empty callback gets called
		this._onViewRemove();
	}
}

ViewSwitcher.prototype.set = function (view) {
	var that = this;
	var prev = this.previous = this.current;

	if (prev === view) {
		return;
	}

	if (this.config.waitForRemove) {
		this.next = view;
		this._hide(prev, view, function () {
			if (that.next === view) {
				delete that.next;
				that._show(view);
			}
		});
	} else {
		this._hide(prev, view);
		this._show(view);
	}
};

ViewSwitcher.prototype._setCurrent = function (view) {
	this.current = view;
	if (view) this._registerRemoveListener(view);
	var emptyCb = this.config.empty;

	if (emptyCb && !this.current) {
		emptyCb();
	}

	return view;
};

ViewSwitcher.prototype.clear = function (cb) {
	this._hide(this.current, null, cb);
};

// If the view switcher itself is removed, remove its child to avoid memory
// leaks
ViewSwitcher.prototype.remove = function () {
	if (this.current) this.current.remove();
};

ViewSwitcher.prototype._show = function (view) {
	var customShow = this.config.show;

	this._setCurrent(view);
	this._render(view);
	if (customShow) customShow(view);
};

ViewSwitcher.prototype._registerRemoveListener = function (view) {
	if (view) view.once('remove', this._onViewRemove, this);
};

ViewSwitcher.prototype._onViewRemove = function (view) {
	var emptyCb = this.config.empty;

	if (this.current === view) {
		this.current = null;
	}
	if (emptyCb && !this.current) {
		emptyCb();
	}
};

ViewSwitcher.prototype._render = function (view) {
	view.parent = this.config.parent;
	if (!view.rendered) view.render({ containerEl: this.el });
	if (!view.insertSelf) {
		if (this.config.prepend) {
			this.el.insertBefore(view.el, this.el.firstChild);
		} else {
			this.el.appendChild(view.el);
		}
	}
};

ViewSwitcher.prototype._hide = function (view, next, cb) {
	var customHide = this.config.hide;

	if (!view) return cb && cb();
	if (customHide) {
		if (customHide.length === 3) {
			customHide(view, next, function () {
				view.remove();
				if (cb) cb();
			});
		} else if (customHide.length === 2) {
			customHide(view, function () {
				view.remove();
				if (cb) cb();
			});
		} else {
			customHide(view);
			view.remove();
			if (cb) cb();
		}
	} else {
		view.remove();
		if (cb) cb();
	}
};


module.exports = ViewSwitcher;
