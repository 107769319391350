var AmpersandState = require('ampersand-state');


module.exports = AmpersandState.extend({
	props: {
		// Logo expanded?
		logo: ['boolean', true, true],
		// Vision & Workshop visible?
		main: ['boolean', true, true],
		// Vision button enabled?
		vision: ['boolean', true, true],
		// Workshop button enabled?
		workshop: ['boolean', true, true],
		// Vertical scroll hint visible?
		hint: ['boolean', true, true],
		// Make contact links visible?
		contact: ['boolean', true, true],
		// Mobile menu toggle visible?
		toggle: ['boolean', true, true],
		// Top line settings override?
		lineTop: ['object', true, function () {
			return {};
		}],
		// Middle line settings override?
		lineMiddle: ['object', true, function () {
			return {};
		}],
		// Bottom line settings override?
		lineBottom: ['object', true, function () {
			return {};
		}],
	},
});
